import React, { useContext, useEffect, useState } from "react";
import {
  modalContent,
  placeHolders,
  messageEnum,
  analyticKeys,
  buttonTitle,
  eventType,
  desc,
} from "@/constants/constants";
import { URL } from "@/utils/service-urls";
import { authenticatedAxios } from "@/utils/axiosInterceptor";
import { CustomAxiosRequestConfig } from "@/types/functionTypes";
import {
  getIntlSuccessMessage,
  getIntlErrorMessage,
  handleMessage,
} from "@/utils/functions";
import { ItemStateContext } from "@/contexts/questionContext";
import useEventLogging from "@/hooks/useLogs";
import { Rate, Spin } from "antd";
import { FeedbackModalComponentProps } from "@/types/componentTypes";

const FeedbackModalComponent: React.FC<FeedbackModalComponentProps> = ({
  assessmentDetailsResponse
}) => {
  //States to store feedback questions and responses
  const [feedbackQuestions, setFeedbackQuestions] = useState<any>({});
  const { steps, setSteps }: any = useContext(ItemStateContext);
  const { logEvent } = useEventLogging();
  const assessmentId = assessmentDetailsResponse.id;
  const [values, setValues] = useState({
    ratings: [] as number[],
    feedback: "",
  });
  const [loader, setLoader] = useState<boolean>(true);
  const numberType = "number";

  // Fetch feedback questions when the component mounts
  useEffect(() => {
    getFeedbackQuestions();
  }, []);

  // Fetch the feedback questions from the server
  const getFeedbackQuestions = async () => {
    try {
      const response = await authenticatedAxios.get(
        URL.ASSESSMENT.CANDIDATE_ASSESSMENT + URL.ASSESSMENT.INVIGILATOR_FEEDBACK_QUESTIONS,
        { authenticated: true } as CustomAxiosRequestConfig,
      );
      setFeedbackQuestions(response?.data?.responseBody);
      setValues((prevValues) => ({
        ...prevValues,
        ratings: response?.data?.responseBody,
      }));
      setLoader(false);
    } catch (error) {
      const errorMsg = getIntlErrorMessage("GENERAL", "API_ERROR");
      handleMessage(messageEnum?.error, errorMsg);
    }
  };
  const getRatings = (prevValues: any, index: any, newValue: any) => {
    return prevValues?.ratings?.map((rating: any, i: any) => {
      return i === index ? newValue : rating;
    });
  };

  const handleRateChange = (index: any) => (newValue: any) => {
    setValues((prevValues) => ({
      ...prevValues,
      ratings: getRatings(prevValues, index, newValue),
    }));
  };

  const handleTextAreaChange = (e: any) => {
    const { value } = e.target;
    setValues((prevValues) => ({
      ...prevValues,
      feedback: value,
    }));
  };

  // Submit feedback and log the event
  const handleSubmitFeedback = () => {
    setSteps(steps);
    logEvent({
      eventName: analyticKeys?.SUBMIT_FEEDBACK,
      eventType: eventType?.POST_ASSESSMENT_ACTION,
    });
    
    feedbackPostCall();
  };

  // Call the API to submit the feedback
  const feedbackPostCall = async () => {
    const checkingNumberRating = values?.ratings
    ?.slice(0, -1) // Exclude the last element
    ?.map((rating) => {
        return typeof rating === numberType ? rating : 0;
    });

    const formattedRatings = checkingNumberRating?.reduce(
      (acc, rating, index) => {
        acc[index + 1] = rating;
        return acc;
      },
      {},
    );
    const feedbackData = {
      ...formattedRatings,
      feedback: values?.feedback,
    };

    const data = {
        requestBody: JSON.stringify(feedbackData)
      };

    try {
        const response = await authenticatedAxios.post(
          URL.ASSESSMENT.CANDIDATE_ASSESSMENT + `/${assessmentId}` +URL.ASSESSMENT.INVIGILATOR_FEEDBACK_RESPONSE,
          data,
          { authenticated: true } as CustomAxiosRequestConfig,
        );
        const successMsg = getIntlSuccessMessage(
            "FEEDBACK_SUBMITTED_MESSAGE",
            "SUBMIT_SUCCESS",
          );
        handleMessage(messageEnum.success, successMsg);
        setValues({
            ratings: [] as number[],
            feedback: "",
        });
        setLoader(false);
      } catch (error) {
        const errorMsg = getIntlErrorMessage("GENERAL", "API_ERROR");
        handleMessage(messageEnum?.error, errorMsg);
      }
  };

  const isFormComplete = () => {
    return (
      values.ratings?.slice(0, -1).every((rating) => typeof rating === numberType && rating > 0) && values.feedback.trim() !== ""
    );
  };

  // Render the feedback questions 
  const renderFeedbackQuestions = () => {
    return feedbackQuestions
      ?.filter((question: any) => question.id !== 8)
      .map((question: any, index: any) => (
        <div
          key={question.question}
          className="flex items-center justify-start space-y-[20px]"
        >
          <p className="w-[70%] pr-[50px] pt-[20px] text-[16px]">
            {question.question}
          </p>
          <Rate
            tooltips={desc}
            onChange={handleRateChange(index)}
            value={values.ratings[index]}
            className="text-[20px] flex space-x-[10px]"
            defaultValue={3}
          />
        </div>
      ));
  };
  
  return (
    <div className="w-full h-screen overflow-auto bg-gray-100">
      <div className="feedback-container mx-auto px-6 py-4 rounded-md shadow-md bg-white">
        <div className="flex flex-col mt-4 font-bold text-lg pb-2 border-b border-gray">
            <div className="flex items-center justify-between">
                <span>{modalContent.rateAssessemt}</span>
                <div>
                    <button
                        className = "px-3 py-2 border-none rounded-md font-normal bg-primary  text-[white]"
                        style={{
                        backgroundColor: isFormComplete() ? "#22c95cfa" : "grey",
                        color: "white",
                        padding: "8px 16px",
                        borderRadius: "8px",
                        cursor: isFormComplete() ? "pointer" : "not-allowed",
                        fontWeight: '400'
                        }}
                        onClick={isFormComplete() ? handleSubmitFeedback : undefined}
                        disabled={!isFormComplete}
                    >
                        {buttonTitle.submitFeedback}
                    </button>
                </div>
            </div>
        </div>

        <div>
          {!loader ? (
            <>
              {renderFeedbackQuestions()}

              {feedbackQuestions && feedbackQuestions.some((question: any) => question.id === 8) && (
                <p className="w-[70%] pr-[50px] pt-[20px] text-[16px]">{feedbackQuestions.find((question: any) => question.id === 8)?.question}</p>
              )}
              <textarea
                rows={5}
                className="w-[85%] border text-[16px] border-solid border-gray text-primaryText p-2 mt-1 rounded-md"
                placeholder={placeHolders.remarks}
                style={{ resize: "none" }}
                value={values.feedback}
                onChange={handleTextAreaChange}
              ></textarea>
            </>
          ) : (
            <div className="flex justify-center py-4">
              <Spin size={"large"} />
            </div>
          )}
        </div>
      </div>
    </div>
  

  );
};

export default FeedbackModalComponent;
