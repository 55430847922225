/* eslint-disable @typescript-eslint/no-unused-vars */
import { ItemStateContext } from "@/contexts/questionContext";
import React, { useContext, useEffect, useState } from "react";
// import LeftComponent from "@/components/leftComponent";
import {
  localStorageKeys,
  messageEnum,
  questionType,
} from "@/constants/constants";
import { authenticatedAxios } from "@/utils/axiosInterceptor";
import { URL } from "@/utils/service-urls";
import { CustomAxiosRequestConfig } from "@/types/functionTypes";
import {
  getIntlErrorMessage,
  handleMessage,
  questionPaperMapping,
} from "@/utils/functions";
import { SetsProps } from "@/types/componentTypes";
import { Spin } from "antd";
import HtmlEditor from "./htmlEditor";
const Sets: React.FC<SetsProps> = ({ selectedSetId }) => {
  // const { selectedLanguage }: any = useContext(ItemStateContext);
  const [setsResponse, setSetsResponse] = useState<any>({});
  useEffect(() => {
    const assessmentId = localStorage.getItem(localStorageKeys?.assessmentId);
    const url = `${URL.ASSESSMENT.CANDIDATE_ASSESSMENT}/${assessmentId}/${URL.ASSESSMENT.CANDIDATE_SET}/${selectedSetId}`;
    try {
      authenticatedAxios
        .get(url, { authenticated: true } as CustomAxiosRequestConfig)
        .then((response) => {
          if (response?.status == 200) {
            questionPaperMapping(JSON.stringify(response?.data?.responseBody));
            const vivaQuestions: any = JSON.parse(
              localStorage.getItem(localStorageKeys?.vivaPaper) ?? "{}",
            );
            const theoryQuestions: any = JSON.parse(
              localStorage.getItem(localStorageKeys?.theoryPaper) ?? "{}",
            );
            const practicalQuestions: any = JSON.parse(
              localStorage.getItem(localStorageKeys?.practicalPaper) ?? "{}",
            );
            let allQuestions: any = {};

            // Include only non-empty question sets
            if (Object.keys(theoryQuestions).length > 0) {
              allQuestions = { ...allQuestions, ...theoryQuestions };
            }
            if (Object.keys(practicalQuestions).length > 0) {
              allQuestions = { ...allQuestions, ...practicalQuestions };
            }
            if (Object.keys(vivaQuestions).length > 0) {
              allQuestions = { ...allQuestions, ...vivaQuestions };
            }
            setSetsResponse(allQuestions);
          } else {
            const errorMsg = getIntlErrorMessage("GENERAL", "API_ERROR");
            handleMessage(messageEnum.error, errorMsg);
          }
        })
        .catch(() => {
          const errorMsg = getIntlErrorMessage("GENERAL", "API_ERROR");
          handleMessage(messageEnum.error, errorMsg);
        });
    } catch (error) {
      const errorMsg = getIntlErrorMessage("GENERAL", "API_ERROR");
      handleMessage(messageEnum.error, errorMsg);
    }
  }, []);

  const getOptions = (question: any) => {
    return (
      <>
        {question.question_type === questionType.filltype && (
          <HtmlEditor
            initialValue={question.options?.[0]?.actual_statement_text?.replace(
              /\[.*?\]/g,
              "_________",
            )}
          />
        )}
        {question.question_type === questionType.matchingType && (
          <div className="flex">
            {question.options?.[0]?.list_values && Object.keys(question.options[0].list_values).map((key) => (
              <div key={key} className="flex flex-col mr-4">
                {question.options?.map((option, index) => (
                  <span key={index} className="mb-2">
                    {option.list_values[key]}
                  </span>
                ))}
              </div>
            ))}
          </div>
        )}
        {(question.question_type === questionType.multipleChoiceType ||
          question.question_type === questionType.multiSelectType ||
          question.question_type === questionType.trueOrfalseType) && (
          <div className="flex-col">
            {question.options?.map((item) => (
              <div key={item.id}>
                <HtmlEditor initialValue={item.option_text} />
              </div>
            ))}
          </div>
        )}
        {/* Handled the condition of null scenario based question */}
        {question.question_type === questionType.scenarioType && (
          <div className="flex-col">
            {question.options?.map(
            (item, index) =>
              item.question_text && (
                <span key={item.id} className="flex">
                  {index + 1}
                  <HtmlEditor initialValue={item.question_text} />
                  {getOptions(item)}
                </span>
              )
          )}
          </div>
        )}
      </>
    );
  };
  return (
    <div className="h-[100%] flex flex-1 flex-col overflow-y-auto">
      {Object?.keys(setsResponse)?.length > 0 ? (
        Object.keys(setsResponse)?.map((language, sectionIndex) => (
          <div key={`${language}-${sectionIndex}`} className="pb-[10px]">
            {setsResponse?.[language]?.length ? (
              <div className="flex flex-col space-y-[10px]">
                <div className="font-semibold">section {sectionIndex + 1}</div>
                {setsResponse?.[language]?.map((question: any, index: any) => {
                  return (
                    <div
                      id={`section-${sectionIndex + 1}-${index + 1}`}
                      key={index}
                      className="flex space-x-[10px]">
                      <div>{index + 1}.</div>
                      <div className="flex-col">
                        <HtmlEditor
                          initialValue={
                            question?.question_text
                          }
                        />
                        {getOptions(question)}
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : null}
          </div>
        ))
      ) : (
        <div className="flex justify-center items-center h-[80vh] py-[20px]">
          <Spin size={"large"} />
        </div>
      )}
    </div>
  );
};

export default Sets;
