import { FC, useContext, useEffect } from "react";
import { ItemStateContext } from "@/contexts/questionContext";
import { getLocationName } from "@/utils/functions";
import { LocationComponentProps } from "@/types/componentTypes";

const LocationComponent: FC<LocationComponentProps> = ({
  onAllow,
  setCurrentLocation,
}) => {
  const { setAllPermissions }: any = useContext(ItemStateContext);

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const address = await getLocationName(
            position.coords.latitude,
            position.coords.longitude,
          );
          setCurrentLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            address: address,
          });

          setAllPermissions((prev: any) => ({
            ...prev,
            locationEnabled: true,
          }));

          onAllow();
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        (error) => {
          setAllPermissions((prev: any) => ({
            ...prev,
            locationEnabled: false,
          }));
        },
      );
    } else {
      setAllPermissions((prev: any) => ({
        ...prev,
        locationEnabled: false,
      }));
    }
  }, [onAllow, setCurrentLocation, setAllPermissions]);

  return <div></div>;
};

export default LocationComponent;
