import { useContext, useEffect } from "react";
import platform from "platform";
import { ItemStateContext } from "@/contexts/questionContext";
import { osTypes } from "@/constants/constants";
import { OsCheckerProps } from "@/types/componentTypes";

function OsChecker({ onAllow }: Readonly<OsCheckerProps>) {
  const { setAllPermissions }: any = useContext(ItemStateContext);
  useEffect(() => {
    const allowedOs = platform?.os?.family;
    if (osTypes.includes(allowedOs)) {
      setAllPermissions((prev: any) => ({
        ...prev,
        osName: platform?.os,
      }));
      onAllow();
    }
  }, []);

  return <div></div>;
}

export default OsChecker;
