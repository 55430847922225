import React from "react";
import { Modal } from "antd";
import ButtonComponent from "@/components/buttonComponent";
import classNames from "classnames";
import { ModalComponentProps } from "@/types/componentTypes";
import { buttonTitle } from "@/constants/constants";

const ModalComponent: React.FC<ModalComponentProps> = ({
  title,
  buttonName,
  modalButtonClick,
  icon,
  iconClassName,
  buttonName2,
  modalButtonClick2,
  isAssessment = false,
  titleClass = "",
  isModalOpen,
  isErrorModals = false,
  closeIcon = false,
  width,
  buttonClass,
  handleOnCancel,
  content,
  contentClass,
  action,
  actionClass,
  questionModal = false,
  className,
  uploadButtonName1,
  uploadButtonName2,
  disabled = false,
  detailsClassName = false,
  titleEndText = false,
  titleEndTextButtonClick,
}) => {
  const modalButtonClass =
    "w-[350px] h-[50px] bg-primary text-[white] rounded-[10px] border-none font-medium text-[16px]";

  const buttonContent = [
    {
      text: buttonName,
      onclick: modalButtonClick,
    },
    {
      text: buttonName2,
      onclick: modalButtonClick2,
    },
  ];

  return (
    <Modal
      title=""
      open={isModalOpen}
      closeIcon={closeIcon}
      className={classNames(
        "flex flex-col items-center",
        isErrorModals
          ? "error-modal"
          : questionModal
            ? className
            : detailsClassName
              ? "detailsClassName"
              : "confirm-modal",
      )}
      centered
      width={width}
      onCancel={handleOnCancel}>
      <div className={iconClassName}>{icon}</div>
      <div className={actionClass}>{action}</div>
      {!titleEndText ? (
        <div className={titleClass}>{title}</div>
      ) : (
        <div className="flex justify-between items-center mr-[30px]">
          <div className={titleClass}>{title}</div>
          <ButtonComponent
            text={buttonTitle?.downloadSample}
            className={
              "px-[10px] py-[5px] text-[14px] border-none rounded-md font-semibold bg-primary  text-[white]"
            }
            onClick={titleEndTextButtonClick}
          />
        </div>
      )}
      <div className={contentClass}>{content}</div>

      {!questionModal &&
        buttonName &&
        (!isAssessment ? (
          <div className={buttonClass}>
            <ButtonComponent
              text={buttonName}
              onClick={modalButtonClick}
              disabled={disabled}
              className={
                buttonClass
                  ? disabled ? 
                  "bg-gray-300 px-[20px] py-[10px] border-none rounded-md font-semibold text-[white] cursor-not-allowed" :
                  "bg-primary px-[20px] py-[10px] border-none rounded-md font-semibold text-[white]"
                  : ""
              }
            />
          </div>
        ) : (
          <div className="flex items-center justify-between space-x-[20px] mt-[10px]">
            {buttonContent?.map((item) => {
              return (
                <ButtonComponent
                  text={item.text}
                  onClick={item.onclick}
                  key={item.text}
                  className={modalButtonClass}
                />
              );
            })}
          </div>
        ))}
      {questionModal && (
        <div className="flex gap-5 justify-end pt-[20px]">
          <ButtonComponent
            text={uploadButtonName1}
            onClick={handleOnCancel}
            className=" border-[1px] border-gray border-solid px-[16px] py-[7px] rounded-md font-semibold"
          />
          <ButtonComponent
            text={uploadButtonName2}
            onClick={modalButtonClick}
            disabled={disabled}
            className=" bg-primary px-[12px] py-[5px] rounded-md font-semibold text-[white]"
          />
        </div>
      )}
    </Modal>
  );
};

export default ModalComponent;
