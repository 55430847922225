import React, { useContext, useEffect, useRef, useState } from "react";
import HtmlEditor from "@/components/htmlEditor";
import { ItemStateContext } from "@/contexts/questionContext";
import { Checkbox, Radio } from "antd";
import FillInTheBlanksQuestion from "@/components/fillInTheBlanks";
import Dragger from "@/components/matchingsDragger";
import {
  questionType,
  analyticKeys,
  eventType,
  delayTiming,
  responseText,
  activityOptions,
  totalParts,
  firebaseMethodKeys,
  subjectOptions,
  activityTypeResult,
  messageEnum,
  vivaData,
} from "@/constants/constants";
import classNames from "classnames";
import useEventLogging from "@/hooks/useLogs";
import {
  debounce,
  getClassName,
  getFirebaseUrl,
  getIntlErrorMessage,
  getIntlSuccessMessage,
  handleMessage,
} from "@/utils/functions";
import { LeftComponentForSingleQuestionProps } from "@/types/componentTypes";
import UploadModalComponent from "./uploadModalComponent";
import { FIREBASE_URL, URL } from "@/utils/service-urls";
import { handleFirebaseOperation } from "../firebase";
import { RootState } from "@/store";
import { useSelector } from "react-redux";
import VideoConferencing from "./videoConferencing";
import RecordVideoModal from "./recordVideo";
import { authenticatedAxios } from "@/utils/axiosInterceptor";
import { MdDeleteOutline } from "react-icons/md";
import { CustomAxiosRequestConfig } from "@/types/functionTypes";

const LeftComponentForSingleQuestion: React.FC<
  LeftComponentForSingleQuestionProps
> = ({
  selectedLanguage,
  currentQuestion,
  handleCheckboxChange,
  handleRadioChange,
  handleTextAreaChange,
  sIndex,
  index,
  isResponsivePage = false,
  ourResponse,
}) => {
  const activityTypeQuestions: any = JSON.parse(
    localStorage.getItem("question_paper_practicals") ?? "{}",
  );
  const vivaQuestions: any = JSON.parse(
    localStorage.getItem("question_paper_viva") ?? "{}",
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const assessmentDetails: any = JSON.parse(
    localStorage.getItem("assessment_details") ?? "{}",
  );
  const {
    currentQuestionIndex,
    responses,
    sectionNumber,
    fromSampleAssessment,
    selectedPart,
    uploadModalOpen,
    setUploadModalOpen,
    activityResponse,
    setActivityResponse,
    isVivaStarted,
    setVivaStarted,
    timer,
    setTimer,
    setVivaResponse,
    recordVideo,
    setRecordVideo,
    setVivaQuestion,
  }: any = useContext(ItemStateContext);
  const { logEvent } = useEventLogging();

  const logEventDebounced = useRef(
    debounce(logEvent, delayTiming?.debounceTimeDelay),
  ).current;

  const [isDeletedFile, setIsDeletedFile] = useState<boolean>(false);
  const candidateId = useSelector((state: RootState) => state.candidateId);
  const assessmentId = useSelector((state: RootState) => state.assessmentId);
  const setId = useSelector((state: RootState) => state.setId);
  const uploadText = {
    uploadVideos: "Upload Video",
    uploadFiles: "Upload Files",
    recordVideo: "Record Video",
  };
  const handleUpload = (text: string) => {
    if (text == uploadText?.uploadVideos) {
      setUploadModalOpen((prev: any) => ({ ...prev, videoModal: true }));
    } else if (text == uploadText?.uploadFiles) {
      setUploadModalOpen((prev: any) => ({ ...prev, fileModal: true }));
    } else if (text == uploadText?.recordVideo) {
      setRecordVideo(true);
    } else {
      setUploadModalOpen({
        fileModal: false,
        videoModal: false,
      });
      setRecordVideo(false);
    }
  };

  const handleGetFilesFromFirebase = async () => {
    try {
      const options = {
        assessmentId: assessmentId,
        setId: setId,
        candidateId: candidateId,
        endpoint: `${FIREBASE_URL?.ENDPOINT?.PRACTICALS}/${currentQuestion?.id}`,
      };
      const firebaseUrl = getFirebaseUrl(options);
      const data = await handleFirebaseOperation(
        firebaseUrl,
        firebaseMethodKeys.GET,
      );
      setActivityResponse((prevResponse: any) => {
        const updatedResponse = { ...prevResponse };
        if (updatedResponse[currentQuestionIndex]) {
          updatedResponse[currentQuestionIndex].visited = true;
          updatedResponse[currentQuestionIndex].uploadedData = data;
        }
        return updatedResponse;
      });
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    handleGetFilesFromFirebase();
  }, [uploadModalOpen, isDeletedFile, recordVideo]);

  useEffect(() => {
    let countdown;
    if (selectedPart === totalParts[2] && timer > 0) {
      countdown = setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);
    }
    if (timer === 0) {
      setVivaStarted(true);
    }
    return () => clearTimeout(countdown);
  }, [selectedPart, timer]);

  useEffect(() => {
    if (selectedPart === totalParts[1]) {
      const defaultAcitivityTpyeResponse = activityTypeQuestions[
        subjectOptions[2].value
      ]?.map(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        (question, index) => ({
          uploadedData: {},
          visited: false,
          reviewed: false,
          questionId: question?.id,
        }),
      );
      setActivityResponse(defaultAcitivityTpyeResponse);
    } else if (selectedPart === totalParts[2]) {
      setVivaQuestion(true);
      const vivaQuestionsWithDefault = vivaQuestions[
        subjectOptions[3].value
      ]?.map(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        (question, index) => ({
          localStream: {},
          visited: false,
          reviewed: false,
        }),
      );
      setVivaResponse(vivaQuestionsWithDefault);
    }
  }, [selectedPart]);

  const handleDeleteFileFromS3 = (file: any, key: any) => {
    setIsDeletedFile(false);
    try {
      const url = `${URL.SERVICES.DELETE}?key=${file}`;
      authenticatedAxios
        .delete(url, { authenticated: true } as CustomAxiosRequestConfig)
        .then(async (response) => {
          if (response?.status === 200) {
            handleDeleteFromFireBase(key);
            setIsDeletedFile(true);
            const successMsg = getIntlSuccessMessage(
              "FILE_DELETED_MESSAGE",
              "DELETE_MESSAGE",
            );
            handleMessage(messageEnum.success, successMsg);
          } else {
            const errorMsg = getIntlErrorMessage(
              "FILE_DELETED_MESSAGE",
              "DELETED_FAILED_MESSAGE",
            );
            handleMessage(messageEnum.error, errorMsg);
          }
        })
        .catch((error) => {
          const errorMsg = getIntlErrorMessage(
            "FILE_DELETED_MESSAGE",
            "DELETED_FAILED_MESSAGE",
          );
          handleMessage(messageEnum.error, errorMsg);
          console.error("Error in deleting file", error);
        });
    } catch (error) {
      const errorMsg = getIntlErrorMessage(
        "FILE_DELETED_MESSAGE",
        "DELETED_FAILED_MESSAGE",
      );
      handleMessage(messageEnum.error, errorMsg);
      console.error("Error:", error);
    }
  };

  const handleDeleteFromFireBase = async (uid: any) => {
    setIsDeletedFile(false);
    try {
      const options = {
        assessmentId: assessmentId,
        setId: setId,
        candidateId: candidateId,
        endpoint: `${FIREBASE_URL?.ENDPOINT?.PRACTICALS}/${currentQuestion?.id}/${uid}`,
      };
      const firebaseUrl = getFirebaseUrl(options);
      await handleFirebaseOperation(firebaseUrl, firebaseMethodKeys.DELETE);
      setIsDeletedFile(true);
    } catch (error) {
      console.log("error", error);
    }
  };
  return (
    <div>
      {selectedPart === totalParts[0] && (
        <div>
          <div className={"px-[32px] pt-[px] flex gap-[1px] mb-[10px]"}>
            {isResponsivePage && <div>{(index ?? 0) + 1}.</div>}
            <HtmlEditor initialValue={currentQuestion?.question_text} />
          </div>
          <div>
            {currentQuestion?.question_type ===
              questionType.multiSelectType && (
              <div className="px-[32px] pb-[10px]">
                {currentQuestion?.options.map(
                  (option: any, optionIndex: number) => {
                    const correctOption = option?.is_correct_option;
                    const questionIndex = sIndex || currentQuestionIndex;
                    const checkboxResponse =
                      responses?.[selectedLanguage]?.[questionIndex]
                        ?.checkboxResponse || [];
                    const scenarioResponse =
                      responses?.[selectedLanguage]?.[questionIndex]
                        ?.scenarioResponse?.checkboxResponse || [];
                    const selectedOption =
                      checkboxResponse.includes(option?.id) ||
                      scenarioResponse.includes(option?.option_text);
                    const isSelectedOptionPresent =
                      checkboxResponse.length > 0 ||
                      scenarioResponse.length > 0;

                    return (
                      <div
                        key={option?.id}
                        className={classNames(
                          !isResponsivePage
                            ? "mt-[10px] px-[10px]"
                            : getClassName({
                                ourResponse,
                                selectedOption,
                                correctOption,
                                isSelectedOptionPresent,
                                defaultClassName: "mt-[10px] px-[10px]",
                              }),
                        )}>
                        {isResponsivePage ? (
                          <div className="flex gap-1">
                            <div>{optionIndex + 1}.</div>
                            <div>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: option?.option_text,
                                }}
                              />
                            </div>
                          </div>
                        ) : (
                          <Checkbox
                            value={option}
                            onChange={() => {
                              if (handleCheckboxChange) {
                                handleCheckboxChange(
                                  option?.option_text,
                                  questionIndex,
                                  option?.id,
                                  currentQuestion?.id,
                                );
                              }
                              if (!fromSampleAssessment) {
                                logEvent({
                                  section: sectionNumber,
                                  questionIndex: currentQuestionIndex,
                                  eventName: analyticKeys?.ANSWER_SELECTION,
                                  eventType: eventType.ASSESSMENT_ACTION,
                                  questionType: currentQuestion?.question_type,
                                  answer: optionIndex + 1,
                                });
                              }
                            }}
                            checked={
                              checkboxResponse.includes(option?.id) ||
                              scenarioResponse.includes(option?.option_text)
                            }>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: option?.option_text,
                              }}
                            />
                          </Checkbox>
                        )}
                        {ourResponse && selectedOption && isResponsivePage && (
                          <div className="absolute text-[12px] text-[white] w-[98.28px] flex items-center justify-center rounded-tl-sm rounded-br-sm bg-[gray] right-0 bottom-[0px] px-[8px] py-[1px]">
                            {responseText?.yourAnswer}
                          </div>
                        )}
                        {!ourResponse &&
                          selectedOption &&
                          isResponsivePage &&
                          selectedOption !== correctOption && (
                            <div className="absolute text-[12px] text-[white] w-[105px] flex items-center justify-center rounded-tl-sm rounded-br-sm bg-[red] right-0 bottom-[0px] px-[8px] py-[1px]">
                              {responseText?.yourAnswer}
                            </div>
                          )}
                        {!ourResponse && correctOption && isResponsivePage && (
                          <div className="absolute text-[12px] text-[white] w-[105px] flex items-center justify-center rounded-tl-sm rounded-br-sm bg-primary right-0 bottom-[0px] px-[8px] py-[1px]">
                            {responseText?.correctAnswer}
                          </div>
                        )}
                        {!ourResponse &&
                          selectedOption &&
                          correctOption &&
                          isResponsivePage &&
                          selectedOption === correctOption && (
                            <div className="absolute text-[12px] text-[white] w-[105px] flex items-center justify-center rounded-tl-sm rounded-br-sm bg-primary right-0 bottom-[0px] px-[8px] py-[1px]">
                              {responseText?.correctAnswer}
                            </div>
                          )}
                      </div>
                    );
                  },
                )}
              </div>
            )}

            {(currentQuestion?.question_type === questionType.trueOrfalseType ||
              currentQuestion?.question_type ===
                questionType.multipleChoiceType) && (
              <div className="px-[32px]" key={currentQuestion?.id}>
                {currentQuestion?.options.map(
                  (option: any, optionIndex: number) => {
                    const correctOption = option?.is_correct_option;
                    const questionIndex = sIndex || currentQuestionIndex;
                    const selectedOption =
                      responses?.[selectedLanguage]?.[questionIndex]
                        ?.radioResponse === option?.id ||
                      responses?.[selectedLanguage]?.[questionIndex]
                        ?.scenarioResponse?.radioResponse ===
                        option?.option_text;
                    const isSelectedOptionPresent =
                      responses?.[selectedLanguage]?.[questionIndex]
                        ?.radioResponse ||
                      responses?.[selectedLanguage]?.[questionIndex]
                        ?.scenarioResponse?.radioResponse ||
                      [];

                    return (
                      <div
                        key={currentQuestion?.id}
                        className={classNames(
                          !isResponsivePage
                            ? "mt-[10px] px-[10px]"
                            : getClassName({
                                ourResponse,
                                selectedOption,
                                correctOption,
                                isSelectedOptionPresent,
                                defaultClassName: "mt-[10px] px-[10px]",
                              }),
                        )}>
                        {isResponsivePage ? (
                          <div className="flex gap-1">
                            <div>{optionIndex + 1}.</div>
                            <div>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: option?.option_text,
                                }}
                              />
                            </div>
                          </div>
                        ) : (
                          <Radio
                            name="radioOption"
                            value={option?.id}
                            onChange={() => {
                              if (handleRadioChange) {
                                handleRadioChange(
                                  option?.option_text,
                                  sIndex ?? currentQuestionIndex,
                                  option?.id,
                                  currentQuestion?.id,
                                );
                              }

                              if (!fromSampleAssessment) {
                                logEvent({
                                  section: sectionNumber,
                                  questionIndex: currentQuestionIndex,
                                  eventName: analyticKeys?.ANSWER_SELECTION,
                                  eventType: eventType.ASSESSMENT_ACTION,
                                  questionType: currentQuestion?.question_type,
                                  answer: optionIndex + 1,
                                });
                              }
                            }}
                            checked={
                              responses?.[selectedLanguage]?.[
                                sIndex || currentQuestionIndex
                              ]?.radioResponse === option?.id ||
                              responses?.[selectedLanguage]?.[
                                sIndex || currentQuestionIndex
                              ]?.scenarioResponse?.radioResponse ===
                                option?.option_text
                            }>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: option?.option_text,
                              }}
                            />
                          </Radio>
                        )}

                        {ourResponse && selectedOption && isResponsivePage && (
                          <div className="absolute text-[12px] text-[white] w-[98.28px] flex items-center justify-center rounded-tl-sm rounded-br-sm bg-[gray] right-0 bottom-[0px] px-[8px] py-[1px]">
                            {responseText?.yourAnswer}
                          </div>
                        )}
                        {!ourResponse &&
                          selectedOption &&
                          isResponsivePage &&
                          selectedOption !== correctOption && (
                            <div className="absolute text-[12px] text-[white] w-[105px] flex items-center justify-center rounded-tl-sm rounded-br-sm bg-[red] right-0 bottom-[0px] px-[8px] py-[1px]">
                              {responseText?.yourAnswer}
                            </div>
                          )}
                        {!ourResponse && correctOption && isResponsivePage && (
                          <div className="absolute text-[12px] text-[white] w-[105px] flex items-center justify-center rounded-tl-sm rounded-br-sm bg-primary right-0 bottom-[0px] px-[8px] py-[1px]">
                            {responseText?.correctAnswer}
                          </div>
                        )}
                      </div>
                    );
                  },
                )}
              </div>
            )}

            {currentQuestion?.question_type === questionType.subJectiveType && (
              <div className="pb-[20px] mx-[36px]">
                <textarea
                  rows={5}
                  className="border-[2px] border-[lightgray] w-[700px]"
                  value={
                    responses?.[selectedLanguage]?.[
                      sIndex ?? currentQuestionIndex
                    ]?.textAreaResponse
                  }
                  readOnly={ourResponse}
                  style={{ resize: "none" }}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (handleTextAreaChange) {
                      handleTextAreaChange(
                        sIndex ?? currentQuestionIndex,
                        value,
                        currentQuestion?.id,
                      );
                    }
                    if (!fromSampleAssessment) {
                      logEventDebounced({
                        section: sectionNumber,
                        questionIndex: currentQuestionIndex,
                        eventName: analyticKeys?.ANSWER_SELECTION,
                        eventType: eventType.ASSESSMENT_ACTION,
                        questionType: currentQuestion?.question_type,
                        answer: value,
                      });
                    }
                  }}></textarea>
              </div>
            )}

            {currentQuestion?.question_type === questionType.filltype && (
              <div className="pb-[20px]">
                <FillInTheBlanksQuestion
                  question={currentQuestion}
                  ourResponse={ourResponse}
                  fillInTheBlanksResponse={
                    responses?.[selectedLanguage]?.[
                      sIndex || currentQuestionIndex
                    ]?.fillInTheBlanksResponse || {}
                  }
                  selectedLanguage={selectedLanguage}
                />
              </div>
            )}

            <div>
              {currentQuestion?.question_type === questionType.matchingType && (
                <div className="pb-[20px]">
                  <Dragger
                    questionId={currentQuestion?.id}
                    listNumber={currentQuestion.list_order_id}
                    initialItems={currentQuestion?.options}
                    selectedLanguage={selectedLanguage}
                    matchingIndex={0}
                    allQuestions={false}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {selectedPart === totalParts[1] && (
        <div
          className="px-[32px]  flex flex-col gap-[20px] mb-[10px] "
          key={index}>
          <div>
            <HtmlEditor initialValue={currentQuestion?.question_text} />
          </div>
          <div className="flex flex-col space-y-[30px]">
            {activityOptions?.map((item: any, index: number) => {
              return (
                <button
                  className="flex space-x-[10px] items-center cursor-pointer"
                  onClick={() => handleUpload(item?.text)}
                  key={index}>
                  <div>{item?.activityIcon}</div>
                  <div>{item?.text}</div>
                </button>
              );
            })}
          </div>
          <div>
            {activityResponse?.[currentQuestionIndex]?.uploadedData &&
              Object.keys(
                activityResponse?.[currentQuestionIndex]?.uploadedData,
              ).length > 0 && (
                <div>
                  <div className="font-bold pb-2">
                    {activityTypeResult?.yourResponse}
                  </div>
                  <div className="uploadFiles pl-3 text-primaryText h-[300px] overflow-y-scroll">
                    {Object.keys(
                      activityResponse?.[currentQuestionIndex]?.uploadedData,
                    ).map((key, index) => {
                      return (
                        <div
                          key={`${key}-${index}`}
                          className="flex justify-between p-2">
                          <div className="flex gap-1">
                            <div>{index + 1}.</div>
                            <div>
                              {
                                activityResponse?.[currentQuestionIndex]
                                  ?.uploadedData[key]?.fileName
                              }
                            </div>
                          </div>

                          <div
                            className="cursor-pointer"
                            onClick={() =>
                              handleDeleteFileFromS3(
                                activityResponse?.[currentQuestionIndex]
                                  ?.uploadedData[key]?.key,
                                key,
                              )
                            }>
                            <MdDeleteOutline />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
          </div>
        </div>
      )}
      {(uploadModalOpen?.fileModal || uploadModalOpen?.videoModal) && (
        <UploadModalComponent
          setUploadModalOpen={setUploadModalOpen}
          uploadModalOpen={uploadModalOpen}
          currentQuestion={currentQuestion}
        />
      )}
      {recordVideo && (
        <RecordVideoModal
          setRecordVideo={setRecordVideo}
          recordVideo={recordVideo}
          currentQuestion={currentQuestion}
        />    
      )}
      {selectedPart === totalParts[2] && (
        <div>
          <div>
            <HtmlEditor initialValue={currentQuestion?.question_text} />
          </div>
          <div>
            {timer > 0 && isVivaStarted === null && (
              <div className="mt-[20px] text-[20px] text-center h-[200px] w-[100%]">
                {`${vivaData?.yourViva} ${timer} ${vivaData?.seconds}`}
              </div>
            )}
            {timer === 0 && isVivaStarted && (
              <VideoConferencing
                setVivaStarted={setVivaStarted}
                isAssessmentDetailsPage={false}
              />
            )}
            {!isVivaStarted && isVivaStarted !== null && (
              <div className="mt-[20px] text-[20px] text-center  h-[200px] w-[100%]">
                {vivaData?.finishViva}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default LeftComponentForSingleQuestion;
