import React from "react";
import { TfiTimer } from "react-icons/tfi";
import { BsBookmarkCheck } from "react-icons/bs";
import { LuNewspaper } from "react-icons/lu";
function AssessmentQuestionsHeader({ assessmentDetailsResponse }) {
  //Fixed handling of time_limit, totol_marks and question_counts
  const qHeaderData = [
    {
      title: "Time Limit",
      value: assessmentDetailsResponse?.question_paper_details?.time_limit,
      icon: <TfiTimer size={18} />,
    },
    {
      title: "Total marks",
      value:
        assessmentDetailsResponse?.question_paper_details?.total_marks,
      icon: <BsBookmarkCheck size={18} />,
    },
    {
      title: "Question Count",
      value:
        assessmentDetailsResponse?.question_paper_details
          ?.count_of_questions,
      icon: <LuNewspaper size={18} />,
    },
  ];
  return (
    <div className="flex justify-between border-gray border-[2px] border-solid  mt-[20px] px-[100px] py-[10px]">
      {qHeaderData?.map((item, index) => {
        return (
          <div key={index} className="flex items-center gap-3">
            {item?.icon}
            <div>
              <div className="text-[14px]">{item?.title}</div>
              <div>{item?.value}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default AssessmentQuestionsHeader;
