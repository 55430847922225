export const messages = {
  LOGIN: {
    INVALID_CREDENTIALS: {
      en: "Invalid credentials",
    },
    EMAIL_REQUIRED: {
      en: "Email is required",
    },
    INVALID_EMAIL: {
      en: "Invalid email address",
    },
    USER_NAME_REQUIRED: {
      en: "Username is required",
    },
    INVALID_USER_NAME: {
      en: "Invalid username",
    },
    PASSWORD_REQUIRED: {
      en: "Password is required",
    },
    PASSWORD_LENGTH: {
      en: "Password length must be more than 7",
    },
  },
  LOGOUT: {
    LOGOUT_ERROR: {
      en: "Error occurred during logout.",
    },
  },
  PERMISSIONS: {
    FAILED_ENTER_FULLSCREEN_ERROR: {
      en: "Failed to enter fullscreen mode.",
    },
    FAILED_EXIT_FULLSCREEN_ERROR: {
      en: "Failed to exit fullscreen mode.",
    },
    FAILED_ACCESS_WEBCAM: {
      en: "Failed to access webcam.",
    },
    CHECK_I_AGREE: {
      en: "Please check the 'I Agree' box before proceeding.",
    },
    PRINT_SCREEN_DISABLED: {
      en: "Print Screen key is disabled.",
    },
    ACCESS_WEBCAM: {
      en: "Failed to access webcam",
    },
  },
  GENERAL: {
    GENERIC_ERROR: {
      en: "An error occurred. Please try again later.",
    },
    API_ERROR: {
      en: "Something went wrong with the API. Please try again later.",
    },
  },
  FEEDBACK_MESSAGES: {
    FEEDBACK_SUBMIT_ERROR: {
      en: "Failed to submit your feedback.",
    },
  },
  ASSESSMENT_SUBMITTED_MESSAGE: {
    SUBMIT_ERROR: {
      en: "Error in posting the data!",
    },
  },
  VPN_DETECTION_MESSAGE: {
    VPN_ERROR: {
      en: "Error fetching IP information:",
    },
  },
  VPN_FETCH_MESSAGE: {
    VPN_HTTP_ERROR: {
      en: "HTTP Error! Status: $statusMsg Text: $textMsg",
    },
  },
  VIOLATIONS: {
    SPECIAL_KEY_ALERT: {
      en: "Please be aware that taking this action could lead to you being logged out of the examination. Thanks for your attention to this matter!",
    },
  },
  IMAGE_POSTED_MESSAGE: {
    POSTED_ERROR: {
      en: "Error in posting the image",
    },
  },
  FIREBASE_UPDATE_MESSAGE: {
    METHOD_ERROR: {
      en: "Invalid method.",
    },
    UPDATE_ERROR: {
      en: "Firebase operation failed: $error",
    },
    MISSING_PARAMS: {
      en: "One or more required parameters are missing.",
    },
  },
  INDEX_DB_MESSAGE: {
    SAVE_ERROR: {
      en: "Error saving chunk to DB",
    },
    DB_UNAVAILABLE: {
      en: "IndexedDB database is not available",
    },
    FETCH_ERROR: {
      en: "Error fetching data from DB: $msg",
    },
    NO_CHUNK_FOUND: {
      en: "No chunks found in the database",
    },
    CONVERT_ERROR: {
      en: "Error converting video to Base64",
    },
    ACCESS_ERROR: {
      en: "Error accessing media devices",
    },
  },
  GEO_FENCING_MESSAGE: {
    LOCATION_MISMATCH_ERROR: {
      en: "Please be careful as you are violating the rules of assessment. Continuing this action will result in automatic logout.",
    },
    NETWORK_CONNECTION_ERROR: {
      en: "Network connection error. Please connect to a stable network.",
    }
  },
  UPLOAD_FILE_VIDEO_MESSAGES: {
    FILE_SIZE_LIMIT_MESSAGE: {
      en: "File size exceeds the limit of 2MB",
    },
    FILE_TYPE_MESSAGES: {
      en: "File type ",
    },
    FILE_TYPE_NOT_SUPPORT: {
      en: " is not supported",
    },
    DUPLICATE_FILE_MESSAGE: {
      en: "File already exists",
    },
  },

  FILE_POSTED_MESSAGE: {
    POSTED_FAILED_MESSAGE: {
      en: "Failed to upload file.Please try again!",
    },
  },
  FILE_DELETED_MESSAGE: {
    DELETED_FAILED_MESSAGE: {
      en: "Failed to delete file.Please try again!",
    },
  },
  ASSESSMENT_DETAILS_MESSAGE: {
    STAKE_HOLDER_MESSAGE: {
      en: "Failed to fetch stakeholders data. Please try again!",
    },
  },
  STAKEHOLDER_LOGS_MESSAGE: {
    STAKEHOLDER_MESSAGE: {
      en: "Failed to post stakeholders logs. Please try again!",
    },
  },
  VIOLATION_MESSAGE: {
    VIOLATION: {
      en: "Failed to post violations. Please try again!",
    },
  },
  ADJUSTED_ASSESSMENT_TIME_MESSAGE: {
    ADJUSTED_TIME: {
      en: "Failed to update the time. Please try again later",
    },
  },
  BLOCKING_THE_CANDIDATE: {
    BLOCK_CANDIDATE: {
      en: "Please enter the reason for blocking the candidate!",
    },
  },
  RAISE_WARNING: {
    RAISE_WARNING_MESSAGE: {
      en: "Please enter the warning message!",
    },
  },
  FLAG_A_VIOLATION: {
    FLAG_A_VIOLATION_MESSAGE: {
      en: "Please enter the violation message!",
    },
  },
  SUBMIT_SCORES: {
    SUBMIT_SCORES_MESSAGE: {
      en: "Failed to submit candidate result",
    },
  },
  MARKS_UPDATE: {
    MARKS_UPDATE_MESSAGE: {
      en: "Failed to update marks",
    },
  },
  PARTIAL_SUBMISSION: {
    PARTIAL_SUBMISSION_ERROR: {
      en: "Partial Submission is not allowed!",
    },
  },
};
