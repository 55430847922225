import { FC, useContext, useEffect, useRef } from "react";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import { ItemStateContext } from "@/contexts/questionContext";
import useEventLogging from "@/hooks/useLogs";
import {
  analyticKeys,
  eventType,
  questionType,
  delayTiming,
} from "@/constants/constants";
import { debounce } from "@/utils/functions";
import { DraggableListProps, DraggerProps } from "@/types/componentTypes";

const DraggableList: FC<DraggableListProps & { ourResponse: boolean }> = ({
  listValues,
  index,
  ourResponse,
}) => {
  const valuesArray = Object.values(listValues) as string[];

  return (
    <div className="flex flex-col gap-[10px]">
      {valuesArray?.map((value: string, subIndex: number) =>
        ourResponse ? (
          <DraggableItem key={`${index}-${subIndex}`} value={value} />
        ) : (
          <Draggable
            key={`${index}-${subIndex}`}
            draggableId={`${index}-${subIndex}`}
            index={subIndex}>
            {(provided: any) => (
              <DraggableItem value={value} provided={provided} />
            )}
          </Draggable>
        ),
      )}
    </div>
  );
};

const DraggableItem: FC<{ value: string; provided?: any }> = ({
  value,
  provided,
}) => {
  const innerRef = provided ? provided.innerRef : null;
  const draggableProps = provided ? provided.draggableProps : {};
  const dragHandleProps = provided ? provided.dragHandleProps : {};

  return (
    <div
      ref={innerRef}
      {...draggableProps}
      {...dragHandleProps}
      className="border-[2px] border-[lightGray] px-[50px] py-[10px] text-center">
      {value}
    </div>
  );
};

const Dragger: FC<DraggerProps> = ({
  questionId,
  initialItems,
  listNumber,
  selectedLanguage,
  matchingIndex,
  allQuestions,
  ourResponse = false,
}) => {
  const {
    items,
    setItems,
    setResponses,
    currentQuestionIndex,
    sectionNumber,
    fromSampleAssessment,
  }: any = useContext(ItemStateContext);
  const { logEvent } = useEventLogging();
  const logEventDebounced = useRef(
    debounce(logEvent, delayTiming?.debounceTimeDelay),
  ).current;

  const matchingList = JSON.parse(
    JSON.stringify(
      initialItems
        ?.filter((item) => item?.is_primary_list)
        ?.map((item) => item?.list_values),
    ),
  );

  const extractedListValues = JSON.parse(
    JSON.stringify(
      initialItems
        ?.filter((item) => !item?.is_primary_list)
        ?.map((item) => item?.list_values),
    ),
  );

  const getKeys = (data: any[]): { key: number; value: any }[][] => {
    const initialDataArray: { key: number; value: any }[][] = [];

    data.forEach((item) => {
      const subArray: { key: number; value: string }[] = Object.values(
        item,
      ).map((value: any, subIndex: number) => ({
        key: subIndex + 1,
        value: value,
      }));

      initialDataArray.push(subArray);
    });

    return initialDataArray;
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const updatedItems = [...items[matchingIndex || currentQuestionIndex]];

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;
    const sourceObjectIndex = parseInt(result.draggableId.split("-")[0], 10);
    const destinationObjectIndex = parseInt(
      result.draggableId.split("-")[0],
      10,
    );

    const sourceObject = updatedItems[sourceObjectIndex];
    const destinationObject = updatedItems[destinationObjectIndex];

    const sourceItem = sourceObject[sourceIndex + 1];
    sourceObject[sourceIndex + 1] = destinationObject[destinationIndex + 1];
    destinationObject[destinationIndex + 1] = sourceItem;

    setItems((prevResponses) => {
      const updatedData = { ...prevResponses };
      updatedData[matchingIndex || currentQuestionIndex] = updatedItems;
      return updatedData;
    });

    const getKeyListValues = getKeys(extractedListValues);
    const updatedMatchingReponse = getKeys(updatedItems);

    const getActualKeys = (initialKeys: any[], finalKeys: any[]) => {
      const actualKeys: number[][] = [];

      for (let i = 0; i < finalKeys.length; i++) {
        const initialKeyMap: { [key: string]: number } = {};
        const finalKeyMap: { [key: string]: number } = {};

        for (const item of initialKeys[i]) {
          initialKeyMap[item.value] = item.key;
        }

        for (const item of finalKeys[i]) {
          finalKeyMap[item.value] = item.key;
        }

        const actualKeyOrder = finalKeys[i].map(
          (item: any) => initialKeyMap[item.value],
        );

        actualKeys.push(actualKeyOrder);
      }

      return actualKeys;
    };

    const actualKeys = getActualKeys(getKeyListValues, updatedMatchingReponse);
    const resultObject: { [key: string]: { [key: number]: number } } = {};

    for (const key in actualKeys) {
      const subArray = actualKeys[key];
      const subObject: { [key: number]: number } = {};

      for (let i = 0; i < subArray.length; i++) {
        subObject[i + 1] = subArray[i];
      }

      resultObject[key] = subObject;
    }
    updateMatchingResponse(resultObject);

    if (!fromSampleAssessment) {
      logEventDebounced({
        section: sectionNumber,
        questionIndex: currentQuestionIndex,
        eventName: analyticKeys?.ANSWER_SELECTION,
        eventType: eventType.ASSESSMENT_ACTION,
        questionType: questionType?.matchingType,
        answer: updatedMatchingReponse,
      });
    }
  };

  const updateMatchingResponse = (finalObject: {
    [key: string]: { [key: number]: number };
  }) => {
    setResponses((prevResponses: any) => {
      const updatedResponses = { ...prevResponses };
      updatedResponses[selectedLanguage][
        currentQuestionIndex ?? matchingIndex
      ].matchingResponse = finalObject;
      updatedResponses[selectedLanguage][
        currentQuestionIndex ?? matchingIndex
      ].questionId = questionId;
      // Set reviewed to false for the updated response
      updatedResponses[selectedLanguage][
        currentQuestionIndex ?? matchingIndex
      ].reviewed = false;
      return updatedResponses;
    });
  };

  useEffect(() => {
    if (!items[matchingIndex || currentQuestionIndex]) {
      setItems((prevItems: any) => {
        const updatedItemsArray = { ...prevItems };
        if (updatedItemsArray !== "") {
          updatedItemsArray[matchingIndex || currentQuestionIndex] =
            extractedListValues;
          return updatedItemsArray;
        }
      });
    }
  }, [matchingIndex || currentQuestionIndex, extractedListValues]);

  return (
    <div
      className={
        allQuestions
          ? ""
          : "flex justify-between items-center w-full flex-wrap pr-[80px] pl-[50px]"
      }>
      <div className="flex justify-between items-center px-[32px] pt-[5px] pb-[10px]">
        <div className="flex flex-col flex-wrap gap-[30px] mr-[50px]">
          {matchingList?.map((listItem: any) =>
            Object.values(listItem).map((value: any, subIndex) => (
              <div key={value}>
                {subIndex + 1}. {value}
              </div>
            )),
          )}
        </div>

        <div className="flex flex-wrap gap-[50px]">
          {ourResponse ? (
            items[matchingIndex || currentQuestionIndex]?.map?.(
              (listValues: any, index: number) => (
                <ul key={`items-${listNumber}-${index}`}>
                  <DraggableList
                    listValues={listValues || {}}
                    index={index}
                    ourResponse={ourResponse}
                  />
                </ul>
              ),
            )
          ) : (
            <DragDropContext onDragEnd={onDragEnd}>
              {items[matchingIndex || currentQuestionIndex]?.map?.(
                (listValues: any, index: number) => (
                  <Droppable
                    key={`items-${listNumber}-${index}`}
                    droppableId={`items-${listNumber}-${index}`}>
                    {(provided: any) => (
                      <ul {...provided.droppableProps} ref={provided.innerRef}>
                        <DraggableList
                          listValues={listValues || {}}
                          index={index}
                          ourResponse={ourResponse}
                        />
                        {provided.placeholder}
                      </ul>
                    )}
                  </Droppable>
                ),
              )}
            </DragDropContext>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dragger;
