import React, { useContext } from "react";
import ButtonComponent from "@/components/buttonComponent";
import { ItemStateContext } from "@/contexts/questionContext";
import useEventLogging from "@/hooks/useLogs";
import {
  analyticKeys,
  buttonTitle,
  eventType,
  subjectOptions,
  totalParts,
} from "@/constants/constants";
import { useStateHandling } from "@/hooks/useStateHandling";
import { LeftComponentButtonsProps } from "@/types/componentTypes";

const LeftComponentButtons: React.FC<LeftComponentButtonsProps> = ({
  selectedLanguage,
  questionIndex,
  isFromAllQuestions = false,
}) => {
  const {
    currentQuestionIndex,
    setResponses,
    responses,
    setCurrentQuestionIndex,
    questions,
    sectionNumber,
    fromSampleAssessment,
    singleQuestionType,
    selectedPart,
    setSelectedLanguage,
    setCurrentQuestion,
    setCurrentSection,
    currentSection,
    setVivaResponse,
    setTimer,
    setVivaStarted,
    setActivityResponse,
    inVivaQuestion,
  }: any = useContext(ItemStateContext);
  const { logEvent } = useEventLogging();
  const response: any = JSON.parse(
    localStorage.getItem("question_paper_theory") ?? "{}",
  );

  const stateHandling = useStateHandling();
  const getSelectedOptionFromResponse = () => {
    const obj = responses[selectedLanguage][currentQuestionIndex];
    const firstKey = Object.keys(obj)?.[0];
    const questionIndex = questions[currentQuestionIndex]?.id;

    const newObj = {
      [questionIndex]: obj[firstKey],
      reviewed: obj.reviewed,
    };
    return newObj;
  };
  const handleNextQuestion = () => {
    setTimer(10);
    setVivaStarted(null);
    if (
      currentQuestionIndex < currentSection?.length - 1 &&
      selectedPart !== totalParts[2]
    ) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
    if (
      currentSection?.length - 1 === currentQuestionIndex &&
      selectedPart === totalParts[0] &&
      Object?.keys(response)?.length > 1
    ) {
      setCurrentQuestionIndex(0);
      setCurrentQuestion(
        response?.[subjectOptions[1].value][currentQuestionIndex],
      );
      setCurrentSection(response?.[subjectOptions[1].value]);
      setSelectedLanguage(subjectOptions[1].value);
    }
    if (
      selectedPart === totalParts[2] &&
      currentQuestionIndex < currentSection?.length - 1 &&
      !inVivaQuestion
    ) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
    if (!fromSampleAssessment && selectedPart !== totalParts[1] && selectedPart !== totalParts[2]) {
      const response = getSelectedOptionFromResponse();
      stateHandling(response);
    }
  };
  const handlePreviousQuestion = () => {
    setTimer(10);
    setVivaStarted(null);
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    } else if (
      currentQuestionIndex === 0 &&
      selectedLanguage === subjectOptions[1].value
    ) {
      const language = subjectOptions[0].value;
      setSelectedLanguage(language);
      setCurrentQuestionIndex(response?.[language]?.length - 1);
      setCurrentSection(response?.[language]);
    }
    if (!fromSampleAssessment && selectedPart !== totalParts[1] && selectedPart !== totalParts[2]) {
      const response = getSelectedOptionFromResponse();
      stateHandling(response);
    }
  };

  const handleMarkForReview = (index) => {
    if (selectedPart === totalParts[0]) {
      const updatedResponses = { ...responses };
      updatedResponses[selectedLanguage][index].reviewed =
        !updatedResponses[selectedLanguage][index].reviewed;
      setResponses(updatedResponses);
    } else if (selectedPart === totalParts[1]) {
      setActivityResponse((prevResponse) => {
        const updatedResponse = { ...prevResponse };
        if (updatedResponse[index]) {
          updatedResponse[index].reviewed = !updatedResponse[index].reviewed;
        }
        return updatedResponse;
      });
    } else if (selectedPart === totalParts[2]) {
      setVivaResponse((prevResponse) => {
        const updatedResponse = { ...prevResponse };
        if (updatedResponse[index]) {
          updatedResponse[index].reviewed = !updatedResponse[index].reviewed;
        }
        return updatedResponse;
      });
    }
    if (!fromSampleAssessment) {
      logEvent({
        section: sectionNumber,
        questionIndex: index,
        eventName: analyticKeys?.MARKED_FOR_REVIEW,
        eventType: eventType?.ASSESSMENT_ACTION,
        questionType: singleQuestionType,
      });
    }
  };

  const nextButtonConditions = () => {
    let condition = false;
    if (selectedPart === totalParts[0]) {
      if (Object?.keys(response)?.length === 1) {
        condition = currentQuestionIndex === currentSection.length - 1;
      } else if (
        Object?.keys(response)?.length > 1 &&
        // eslint-disable-next-line no-prototype-builtins
        response.hasOwnProperty("Hindi")
      ) {
        condition =
          currentQuestionIndex === currentSection.length - 1 &&
          selectedLanguage === subjectOptions[1]?.value;
      }
    } else if (selectedPart === totalParts[1]) {
      condition = currentQuestionIndex === currentSection.length - 1;
    } else if (selectedPart === totalParts[2]) {
      condition = currentQuestionIndex === currentSection.length - 1;
    }
    return condition;
  };
  const previousButtonConditions = () => {
    let condition = false;
    if (selectedPart === totalParts[0]) {
      condition =
        currentQuestionIndex === 0 &&
        selectedLanguage === subjectOptions[0]?.value;
    } else if (selectedPart === totalParts[1]) {
      condition = currentQuestionIndex === 0;
    } else if (selectedPart === totalParts[2]) {
      condition = currentQuestionIndex === 0;
    }
    return condition;
  };
  const nextButtonDisableConditions = () => {
    let condition = false;
    if (selectedPart === totalParts[0]) {
      if (Object?.keys(response)?.length === 1) {
        condition = currentQuestionIndex === currentSection.length - 1;
      } else if (
        Object?.keys(response)?.length > 1 &&
        // eslint-disable-next-line no-prototype-builtins
        response.hasOwnProperty("Hindi")
      ) {
        condition =
          currentQuestionIndex === currentSection.length - 1 &&
          selectedLanguage === subjectOptions[1]?.value;
      }
    } else if (selectedPart === totalParts[1]) {
      condition = currentQuestionIndex === currentSection.length - 1;
    } else if (selectedPart === totalParts[2]) {
      condition =
        inVivaQuestion || currentQuestionIndex === currentSection.length - 1;
    }
    return condition;
  };
  const previousButtonDisableConditions = () => {
    let condition = false;
    if (selectedPart === totalParts[0]) {
      condition =
        currentQuestionIndex === 0 &&
        selectedLanguage === subjectOptions[0]?.value;
    } else if (selectedPart === totalParts[1]) {
      condition = currentQuestionIndex === 0;
    } else if (selectedPart === totalParts[2]) {
      condition = inVivaQuestion || currentQuestionIndex === 0;
    }
    return condition;
  };

  const buttonConfigurations = [
    {
      condition: previousButtonConditions(),
      className:
        "bg-[lightGray] border-none py-[5px] px-[10px] text-primaryText rounded-md",
      onClick: handlePreviousQuestion,
      disabled: previousButtonDisableConditions(),
      text: buttonTitle?.previous,
    },
    {
      condition: nextButtonConditions(),
      className:
        "bg-[lightGray] border-none py-[5px] px-[10px] text-primaryText rounded-md",
      onClick: handleNextQuestion,
      disabled: nextButtonDisableConditions(),
      text: buttonTitle?.next,
    },
  ];
  return (
    <div>
      {isFromAllQuestions ? (
        <ButtonComponent
          className="bg-primary border-none py-[5px] px-[10px] text-[white] rounded-md"
          onClick={() =>
            handleMarkForReview(
              isFromAllQuestions ? questionIndex : currentQuestionIndex,
            )
          }
          text={buttonTitle?.markAsReview}
        />
      ) : (
        <div className="flex justify-between p-[10px] pl-0 sticky bottom-0 w-full bg-[white]">
          <ButtonComponent
            className="bg-primary border-none py-[5px] px-[10px] text-[white] rounded-md"
            onClick={() =>
              handleMarkForReview(
                isFromAllQuestions ? questionIndex : currentQuestionIndex,
              )
            }
            text={buttonTitle?.markAsReview}
          />
          <div className="flex gap-[10px]">
            {buttonConfigurations.map((config) => {
              return (
                <ButtonComponent
                  key={config.text}
                  className={
                    config.condition
                      ? config.className
                      : "bg-primary border-none py-[5px] px-[10px] text-[white] rounded-md"
                  }
                  onClick={config.onClick}
                  disabled={config.disabled}
                  text={config.text}
                />
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default LeftComponentButtons;
