import React, { useContext, useEffect } from "react";
import { ItemStateContext } from "@/contexts/questionContext";
import { MicroPhoneStatusProps } from "@/types/componentTypes";

const MicrophoneStatus: React.FC<MicroPhoneStatusProps> = ({ onAllow }) => {
  const { setAllPermissions }: any = useContext(ItemStateContext);
  useEffect(() => {
    if (navigator?.mediaDevices?.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then(() => {
          setAllPermissions((prev: any) => ({
            ...prev,
            microphoneEnabled: true,
          }));
          onAllow();
        })
        .catch(() => {
          setAllPermissions((prev: any) => ({
            ...prev,
            microphoneEnabled: false,
          }));
        });
    } else {
      setAllPermissions((prev: any) => ({
        ...prev,
        microphoneEnabled: false,
      }));
    }
  }, []);
  return <div></div>;
};

export default MicrophoneStatus;
