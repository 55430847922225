import React, { useCallback, useContext, useRef, useState } from "react";
import Header from "@/components/header";
import WebcamComponent from "@/components/webcamComponent";
import DosAndDonts from "@/components/dosAndDonts";
import {
  contentDivText,
  thingsToRemember,
  firebaseMethodKeys,
  webcamTypes,
  folderTypes,
  analyticKeys,
  eventType,
} from "@/constants/constants";
import { ItemStateContext } from "@/contexts/questionContext";
import { ItemStateContextValues } from "@/types/contextTypes";
import { authenticatedAxios } from "@/utils/axiosInterceptor";
import { CustomAxiosRequestConfig } from "@/types/functionTypes";
import { FIREBASE_URL, URL } from "@/utils/service-urls";
import { useSelector } from "react-redux";
import { RootState } from "@/store";
import { getFirebaseUrl } from "@/utils/functions";
import { handleFirebaseOperation } from "@/firebase";
import { VerficationScreenProps } from "@/types/componentTypes";
import FeedbackModalComponent from "@/components/feedbackModalComponent";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import useEventLogging from "@/hooks/useLogs";

const VerficationScreen: React.FC<VerficationScreenProps> = ({
  isFromQuestions = false,
  feedBackModal,
  setFeedBackModal,
  idVerification = true,
  faceVerification = true,
}) => {
  const assessmentDetails: any = JSON.parse(
    localStorage.getItem("assessment_details") ?? "{}",
  );
  const { logEvent } = useEventLogging();
  const navigate = useNavigate();
  const webcamRef = useRef<any>(null);
  const [idVerifyStatus, setIdVerifyStatus] = useState(!faceVerification);
  const {
    setVerificationImage,
    verificationImage,
    isModalOpen,
    setIsModalOpen,
    setSteps,
    steps,
  }: ItemStateContextValues = (useContext(ItemStateContext) ||
    {}) as ItemStateContextValues;
  const candidateId = useSelector((state: RootState) => state.candidateId);
  const assessmentId = useSelector((state: RootState) => state.assessmentId);
  const setId = useSelector((state: RootState) => state.setId);
  const captureFace = useCallback(async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    await handleUploadToDB(webcamTypes?.candidate, imageSrc, false);
    setVerificationImage({ face: imageSrc });
  }, []);

  const handleRetakeFace = () => {
    setVerificationImage({ face: null });
  };

  const handleVerifyFace = () => {
    if (!isFromQuestions) {
      if (idVerification) setIdVerifyStatus(true);
      setIsModalOpen(false);
    } else {
      // Handling the case when feedback form not selected
      if(assessmentDetails?.rules?.showFeedbackQuestions){
        setFeedBackModal(true);
      }
      else if(assessmentDetails?.rules?.showResponsesToCandidates ||  assessmentDetails?.rules?.showResultsToCandidates){
        logEvent({
          eventName: analyticKeys?.VIEW_RESPONSES,
          eventType: eventType?.POST_ASSESSMENT_ACTION,
        });
        setSteps(steps+1);
      }
      else{
        navigate(URL.ACCOUNT.LOGIN);
      }
    }
  };

  const captureId = useCallback(async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    await handleUploadToDB(webcamTypes?.id, imageSrc, true);
    setVerificationImage({ id: imageSrc });
  }, []);

  const handleRetakeId = () => {
    setVerificationImage({ face: null });
  };

  const handleVerifyId = () => {
    setIsModalOpen(true);
  };
  const handleUploadToDB = async (type, imageSrc, setKey) => {
    const imageData = {
      requestBody: {
        folder: folderTypes?.imageType,
        file: imageSrc,
      },
    };
    try {
      const url = URL.SERVICES.UPLOAD;

      authenticatedAxios
        .post(url, imageData, {
          authenticated: true,
        } as CustomAxiosRequestConfig)
        .then(async (response) => {
          await handleUploadToFirebase(
            type,
            setKey
              ? response.data.responseBody.key
              : response.data.responseBody,
          );
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleUploadToFirebase = async (type, data) => {
    try {
      const options = {
        assessmentId: assessmentId,
        setId: setId,
        candidateId: candidateId,
        endpoint:
          type === webcamTypes.id
            ? `${FIREBASE_URL?.ENDPOINT?.EVIDENCES}/${type}`
            : `${FIREBASE_URL?.ENDPOINT?.EVIDENCES}/${type}/${moment.now()}`,
      };
      const firebaseUrl = getFirebaseUrl(options);
      await handleFirebaseOperation(firebaseUrl, firebaseMethodKeys.PUT, data);
    } catch (error) {
      console.log("error", error);
    }
  };
  return (
    <div>
      <Header heroHeader={true} />

      <div className="flex">
        <div className="w-[35%] py-[48px] pl-[50px] pr-[34px] border-r-2 min-h-calc-vh border-gray">
          <div className="text-primaryText font-bold text-[20px] pb-4">
            {contentDivText.thingsToRemember}
          </div>
          <div>
            {thingsToRemember?.map((item, index) => {
              return (
                <div
                  className="leading-8 text-[15px] text-primaryText"
                  key={item?.point}>
                  {index + 1}. {item?.point}
                </div>
              );
            })}
          </div>
        </div>
        <div className="border-r-2 min-h-calc-vh border-gray w-[80%]">
          <WebcamComponent
            title={
              !idVerifyStatus
                ? contentDivText.faceVerification
                : contentDivText.idVerification
            }
            content={
              !idVerifyStatus
                ? contentDivText.faceVerificationContent
                : contentDivText.idVerificationContent
            }
            webcamRef={webcamRef}
            handleCapture={!idVerifyStatus ? captureFace : captureId}
            capturedImage={
              !idVerifyStatus ? verificationImage?.face : verificationImage?.id
            }
            handleRetake={!idVerifyStatus ? handleRetakeFace : handleRetakeId}
            handleVerify={!idVerifyStatus ? handleVerifyFace : handleVerifyId}
            isModalOpen={!idVerifyStatus ? false : isModalOpen}
            isFromQuestions={isFromQuestions}
          />
        </div>
        <DosAndDonts idVerifyStatus={idVerifyStatus} />
        {feedBackModal && (
          <FeedbackModalComponent
            feedBackModal={feedBackModal}
            setFeedBackModal={setFeedBackModal}
          />
        )}
      </div>
    </div>
  );
};

export default VerficationScreen;
