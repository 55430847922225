import React, { useContext, useEffect } from "react";
import { ItemStateContext } from "@/contexts/questionContext";
import { environmentVariables } from "@/constants/constants";
import { vpnCheck } from "@/utils/functions";
import { VpnCheckProps } from "@/types/componentTypes";

const VpnCheck: React.FC<VpnCheckProps> = ({ onAllow }) => {
  const { setAllPermissions, setViolationChecks }: any =
    useContext(ItemStateContext);
  const checkVPN = async () => {
    try {
      const data = await vpnCheck();

      const isVpnEnabled =
        process.env.NODE_ENV === environmentVariables?.dev
          ? data?.threat?.is_threat
          : data?.threat?.is_vpn;
      setViolationChecks({
        ip: data?.ip,
        city: data?.city,
        region: data?.region,
        vpn: isVpnEnabled,
      });
      setAllPermissions((prev: any) => ({
        ...prev,
        vpnDisabled: !isVpnEnabled,
      }));
      if (!isVpnEnabled) {
        onAllow?.();
      }
    } catch (error) {
      onAllow();
    }
  };

  useEffect(() => {
    checkVPN();
  }, []);
  return <div></div>;
};

export default VpnCheck;
