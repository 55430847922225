import React from "react";

function Logo({ heroheader, logo }: { heroheader?: any; logo?: string }) {
  return (
    <div>
      {logo ? (
        <img src={logo} alt="Logo" width="120"
        height="35"/>
      ) : (
        <svg
          id="Layer_1"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="10 310 1080 380"
          style={{ fill: heroheader ? "#01af67" : "#ffffff" }}>
          <path
            className="cls-1"
            d="M259.84,555c-2.78,9.62-7.95,24.08-11,30.38-5.66,1.2-21.31,4.85-36.42,4.85-48.6,0-72.46-32.12-72.46-66.34,0-40.67,30.92-68.67,76-68.67,17.13,0,31.3,3.68,37.58,4.61.83,9,2.22,21.45,3.77,31.17l-6.48,1.4c-4-13.72-9-21.73-19.21-25.9-5.1-2.32-13.08-3.6-20.24-3.6-32.09,0-48.89,24.22-48.89,56.74,0,38,19.67,62.67,51,62.67,19.64,0,29.79-9.23,40-29.48Z"
          />
          <path
            className="cls-1"
            d="M349.36,569.84c-12.61,16.26-26.89,19.75-33.61,19.75-24.69,0-39.47-19.85-39.47-42.86,0-13.59,5.09-26.33,13.28-35,8.46-9.28,19.43-14.41,29.88-14.41,17.69,0,30.88,15.13,30.85,31.11-.17,3.87-.69,5.94-3.73,6.7-3.94.83-29.86,2.55-52.73,3.35-.35,25.64,15.13,36.54,29.29,36.54,8.08,0,15.67-3.27,23-9.93ZM314.81,505c-8.94,0-17.49,8.7-20,24.73,10.6,0,21.09-.07,32.21-.59,3.44,0,4.63-.93,4.63-4C331.86,514.74,325.33,505,314.81,505Z"
          />
          <path
            className="cls-1"
            d="M410.06,587.21H363.11v-5.79c12.17-1.19,13.33-2.18,13.33-14.93V524.74c0-12.55-.8-13.22-11.94-14.72v-5.18a122.78,122.78,0,0,0,29.43-8v21.8c7.32-11,15.46-21.24,25.08-21.24,7.35,0,11.78,4.65,11.78,10,0,5.51-3.86,10.1-7.37,12a4.18,4.18,0,0,1-5.35-.5c-3.05-3-5.47-5.15-9-5.15-4,0-10.89,5.52-15.12,15.19v37.46c0,12.91,1.07,13.83,16.13,15.09Z"
          />
          <path
            className="cls-1"
            d="M483,587.08a20.4,20.4,0,0,1-8.45,2.51c-13.19,0-20.38-7.65-20.38-24V508.21H440.59l-.87-2.38,5.44-6.09h9V483.07c4.32-4.12,10.8-9.5,15.15-13.62l2.91.61c-.36,7.81-.6,19.17-.56,29.68H493.8c1.79,2,1.15,6.68-1.27,8.47h-20.9v50.51c0,16,6.36,18.77,11.28,18.77a28,28,0,0,0,11.67-3l1.82,5.53Z"
          />
          <path
            className="cls-1"
            d="M556.84,504c9.56-1.42,20.43-4.88,30.22-8.62V511c.57-.36,15.94-9.25,16.7-9.62,4.8-2.65,9-4,11.78-4,21.87,0,35.05,18.62,35.05,38.19,0,30-23.72,49.14-49.69,54.05a33.38,33.38,0,0,1-13.84-3.26v26.31c0,14.07,1.49,15.26,16.51,16.73v5.74H555.34v-5.74c12.78-1.36,14.23-2.5,14.23-15.39V523.83c0-11.71-.54-12.58-12.73-14.26Zm30.22,68.22a28.52,28.52,0,0,0,18.43,7.68c16.08,0,26.61-14.22,26.61-36.35s-12.46-33.64-25.82-33.64c-6.6,0-14.2,4.11-19.22,7.8Z"
          />
          <path
            className="cls-1"
            d="M663.28,587.21v-5.79c12.48-1.3,13.85-2.29,13.85-15.31V471.73c0-11.81-1-13-13.32-14.7v-5.38a152.64,152.64,0,0,0,30.82-6.76V566.11c0,13.13,1.09,14.08,13.72,15.31v5.79Z"
          />
          <path
            className="cls-1"
            d="M784.1,589.59c-3.41,0-7.79-1.62-9.88-3.85a18.63,18.63,0,0,1-4.85-8.66c-7.88,5.3-17.62,12.51-23.36,12.51a24.7,24.7,0,0,1-24.76-24.67c0-9.89,5.27-16,16.13-19.81,12.06-4.21,27.11-9.31,31.52-13v-4.84c0-13.44-6.55-21.16-16.56-21.16a11.22,11.22,0,0,0-9.1,4.47c-2.22,2.85-3.51,7.16-5.26,13-1.06,3.57-3.1,5-6.27,5-4,0-9.28-4.12-9.28-9,0-2.93,2.55-5.35,6.63-8.2,6.34-4.5,17.91-11.57,29.21-14a28.79,28.79,0,0,1,17.38,5.44c7.24,6,10.28,13,10.28,23.32v37.66c0,9.21,3.35,11.93,6.85,11.93a15.69,15.69,0,0,0,7-2.08l2,5.78Zm-15.2-50.27c-4.23,2.15-12.7,5.93-17.16,8-7.31,3.34-11.7,7.09-11.7,14.33,0,10.65,7.91,15.28,13.92,15.28,4.74,0,11.11-2.64,14.94-6.44Z"
          />
          <path
            className="cls-1"
            d="M846.42,587.08a20.36,20.36,0,0,1-8.44,2.51c-13.19,0-20.38-7.65-20.38-24V508.21H804.06l-.87-2.38,5.44-6.09h9V483.07c4.31-4.12,10.79-9.5,15.14-13.62l2.92.61c-.37,7.81-.6,19.17-.56,29.68h22.16c1.79,2,1.16,6.68-1.26,8.47H835.1v50.51c0,16,6.36,18.77,11.28,18.77a27.92,27.92,0,0,0,11.66-3l1.83,5.53Z"
          />
          <path
            className="cls-1"
            d="M939.09,569.84c-12.62,16.26-26.89,19.75-33.61,19.75-24.69,0-39.48-19.85-39.48-42.86,0-13.59,5.1-26.33,13.29-35,8.45-9.28,19.43-14.41,29.87-14.41,17.69,0,30.89,15.13,30.85,31.11-.16,3.87-.68,5.94-3.72,6.7-3.94.83-29.86,2.55-52.73,3.35-.35,25.64,15.13,36.54,29.29,36.54,8.08,0,15.67-3.27,23-9.93ZM904.53,505c-8.93,0-17.48,8.7-20,24.73,10.6,0,21.09-.07,32.21-.59,3.44,0,4.63-.93,4.63-4C921.59,514.74,915.06,505,904.53,505Z"
          />
          <path
            className="cls-1"
            d="M527.28,472.93a11.32,11.32,0,0,1-11-11,11.17,11.17,0,0,1,11.16-11.38c5.8,0,10.55,4.76,10.55,11.38A11,11,0,0,1,527.28,472.93Zm-2.2,117.28q.21-24.5.41-49a7.42,7.42,0,0,1-5.08-8.29,7.17,7.17,0,0,1,5.27-5.21l.06-42.39a1.75,1.75,0,0,0-1.06-1.88,1.67,1.67,0,0,0-1.51.5c-1,.93-.45,2.55-.38,2.76a3.5,3.5,0,0,0,1.32,1.76A175.59,175.59,0,0,1,498.8,540.6a68.3,68.3,0,0,1,10.55,17.58,69.4,69.4,0,0,1,5,30.27,19.48,19.48,0,0,0,7.41,1.88A19.13,19.13,0,0,0,525.08,590.21Zm3.4.13q-.19-24.48-.41-49a7.42,7.42,0,0,0,5.09-8.29,7.18,7.18,0,0,0-5.28-5.21l-.06-42.38a1.75,1.75,0,0,1,1.07-1.89,1.71,1.71,0,0,1,1.51.5c1,.93.44,2.56.37,2.77a3.51,3.51,0,0,1-1.32,1.75,175.81,175.81,0,0,0,25.31,52.13,68.49,68.49,0,0,0-15.57,47.85,19.72,19.72,0,0,1-7.41,1.88A18.19,18.19,0,0,1,528.48,590.34Z"
          />
        </svg>
      )}
    </div>
  );
}

export default Logo;
