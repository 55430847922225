import React, { useContext, useEffect, useState } from "react";
import ModalComponent from "@/components/customModal";
import {
  buttonTitle,
  recordVideoButtons,
  recordVideoKeys,
  messageEnum,
  localStorageKeys,
  firebaseMethodKeys
} from "@/constants/constants";
import moment from "moment";
import { RecordVideoModalPros } from "@/types/componentTypes";
import { ZegoExpressEngine } from "zego-express-engine-webrtc";
import classNames from "classnames";
import axios from "axios";
import { authenticatedAxios } from "@/utils/axiosInterceptor";
import { LoadingOutlined } from "@ant-design/icons";
import { Progress, Spin } from "antd";
import { CustomAxiosRequestConfig } from "@/types/functionTypes";
import { FIREBASE_URL, URL } from "@/utils/service-urls";
import { handleFirebaseOperation } from "../firebase";
import { getFirebaseUrl, getIntlErrorMessage, handleMessage } from "@/utils/functions";
import { ItemStateContext } from "@/contexts/questionContext";

const RecordVideoModal: React.FC<RecordVideoModalPros> = ({
  setRecordVideo,
  recordVideo,
  currentQuestion
}) => {
  const { stopTimer, setStopTimer, activityResponse}: any = useContext(ItemStateContext);
  const date = new Date();
  const formattedDate = moment(date).format("MMMM D, YYYY");
  const [localStream, setLocalStream] = useState<any | null>(null);
  const assessmentId = localStorage.getItem(localStorageKeys.assessmentId);
  const user_id = localStorage.getItem("user_id");
  const org_id = localStorage.getItem("org_id");
  const candidate_id = localStorage.getItem("candidate_id");
  const setId = localStorage.getItem("set_id");
  const [uploading, setUploading] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>();
  const [streamId, setStreamId] = useState("");
  const [zegoInstance, setZegoInstance] = useState<any>(null);
  const [permissions, setPermissions] = useState({
    videoStopped: false,
    audioStopped: false,
    shareStopped: false,
    recordingStarted: false,
  });
  const [taskId, setTaskId] = useState<any>();
  const streamIdNum = "170";
  const SignatureNonce = "15215528852396";
  const AppId = process.env.REACT_APP_ZEGO_APP_ID;
  const headers = { "Content-type": "application/json;charset=utf-8" };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [screenStreamId, setScreenStreamId] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [screenStream, setScreenStream] = useState<any>(null);
  const generateToken = async () => {
    try {
      const response = await authenticatedAxios.get(
        URL.ACCOUNT.GET_ZEGO_TOKEN + `/${assessmentId}` + URL.ACCOUNT.TOKEN,
        {
          authenticated: true,
        } as CustomAxiosRequestConfig,
      );
      return response;
    } catch (error) {
      const errorMsg = getIntlErrorMessage("GENERAL", "API_ERROR");
      handleMessage(messageEnum?.error, errorMsg);
    }
  };
  useEffect(() => {
    const initializeApp = async () => {
      const response = await generateToken();
      const zegoAppId = process.env.REACT_APP_ZEGO_APP_ID;
      const zegoServerId = process.env.REACT_APP_ZEGO_SERVER_ID;
      const zegoToken = response?.data?.responseBody?.data?.token;

      if (
        typeof zegoAppId !== "string" ||
        typeof zegoServerId !== "string" ||
        typeof zegoToken !== "string"
      ) {
        console.error(
          "Zego environment variables are not defined or not of type string.",
        );
        return;
      }

      const zg = new ZegoExpressEngine(parseInt(zegoAppId), zegoServerId);
      try {
        await zg.loginRoom(
          streamIdNum,
          zegoToken,
          {
            userID: response?.data?.responseBody?.data?.user_id,
            userName: "Prasad",
          },
          { userUpdate: true },
        );
        setZegoInstance(zg);
      } catch (error) {
        console.error("Error during login:", error);
      }
      try {
        const stream = await zg.createZegoStream({
          camera: { audio: true, video: true },
        });
        setLocalStream(stream);
        const streamID = streamIdNum + Date.now();
        setStreamId(streamID);
        zg.startPublishingStream(streamID, stream);
      } catch (error) {
        console.error("Error occurred while creating local stream:", error);
      }
    };

    initializeApp();
  }, []);

   // Fetch the signature and timestamp from your backend or signature generation API
   const getSignatureAndTimestamp = async () => {
    try {
      const data = {
        requestBody: { 
          "org_id": org_id,
          "user_id": user_id,
          "signature_nonce": SignatureNonce}
      };
      const response = await authenticatedAxios.post(
        `/candidates/conference/${assessmentId}/signature`,
        data,
        { authenticated: true } as CustomAxiosRequestConfig,
      );
      return response?.data?.responseBody?.data;
    } catch (error) {
      console.error("Error fetching signature:", error);
      throw error; // Stop execution if signature generation fails
    }
  };

  // Function to generate UTC time in the required format
  const getFileUrl = async () => {
    const bodyData = {
      TaskId: taskId, // Ensure taskId is available
    };
  
    const { signature, timestamp, signature_nonce } = await getSignatureAndTimestamp();
  
    const url = `https://cloudrecord-api.zego.im/?Action=DescribeRecordStatus&AppId=${AppId}&SignatureNonce=${signature_nonce}&Timestamp=${timestamp}&Signature=${signature}&SignatureVersion=2.0&IsTest=false`;
  
    try {
      // Await the axios request
      const response = await axios.post(url, bodyData, { headers });
  
      // Extract the FileId from the response
      const responseData = response.data;
      const fileId = responseData.Data.RecordFiles[0]?.FileId;  // Check for valid response
  
      // Return the fileId or null if not found
      return fileId || null;
    } catch (error) {
      console.error("Error fetching file ID:", error);
  
      // Return null in case of an error
      return null;
    }
  };


  const handleStart = async () => {

    const bodyData = {
      RoomId: streamIdNum,
      RecordInputParams: {
        RecordMode: 2,
        StreamType: 3,
        MaxIdleTime: 60,
        MixConfig: {
          MixMode: 2,
          MixOutputStreamId: "mix",
          MixOutputVideoConfig: {
            Width: 1280,
            Height: 720,
            Fps: 15,
            Bitrate: 1130000,
          },
        },
      },
      RecordOutputParams: {
        OutputFileFormat: "mp4",
        OutputFolder: "record/",
      },
      StorageParams: {
        Vendor: 1,
        Region: "ap-south-1",
        Bucket: "certiplate-v3-dev-private",
        AccessKeyId: "AKIA6FC7BVS2DOIHWUWC",
        AccessKeySecret: "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",
      },
    };

    const { signature, timestamp, signature_nonce } = await getSignatureAndTimestamp();
    const url = `https://cloudrecord-api.zego.im/?Action=StartRecord&AppId=${AppId}&SignatureNonce=${signature_nonce}&Timestamp=${timestamp}&Signature=${signature}&SignatureVersion=2.0&IsTest=false`;
    try {
      const response = await axios.post(url, bodyData, {headers})
      const responseData = response.data;
      setTaskId(responseData?.Data?.TaskId);
      setPermissions((prev) => ({ ...prev, recordingStarted: true }));
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handlePause = async () => {
  
    const bodyData = {
      TaskId: taskId,
    };
  
    const { signature, timestamp, signature_nonce } = await getSignatureAndTimestamp();
  
    const url = `https://cloudrecord-api.zego.im/?Action=StopRecord&AppId=${AppId}&SignatureNonce=${signature_nonce}&Timestamp=${timestamp}&Signature=${signature}&SignatureVersion=2.0&IsTest=false`;
  
    try {
      // Stop Recording
      const response = await axios.post(url, bodyData, { headers });
  
      setPermissions((prev) => ({ ...prev, recordingStarted: false }));
  
    } catch (error) {
      console.error("Error:", error);
    }
  };
  
  

  const handleStopVideo = async () => {
    if (zegoInstance) {
      try {
        if (!permissions.videoStopped) {
          setPermissions((prev) => ({ ...prev, videoStopped: true }));
          await zegoInstance.mutePublishStreamVideo(localStream, true);
        } else {
          setPermissions((prev) => ({ ...prev, videoStopped: false }));
          await zegoInstance.mutePublishStreamVideo(localStream, false);
        }
      } catch (error) {
        console.error("Error occurred while toggling video:", error);
      }
    }
  };

   const downloadVideoFromURL = async (key) => {
        try {
          const data = {
            requestBody: { 
              "org_id": org_id,
              "user_id": user_id,
              "assessment_id": assessmentId,
              "candidate_id": candidate_id,
              "key":  `record/${key}`
            }
          };
  
          const response = await authenticatedAxios.post(
            '/candidates/download',
            data,
            { authenticated: true } as CustomAxiosRequestConfig,
          );
  
          // Ensure response.data.responseBody.url contains the expected buffer
          const bufferData = response.data.responseBody.url.data;
  
          if (!bufferData) {
            console.error('Error: No video data returned.');
            return;
          }
  
          // If the data is not in the correct format, you may need to convert it into a Uint8Array
          const uint8Buffer = new Uint8Array(bufferData);
  
          // Create a Blob from the Uint8Array and ensure the mime type is correct
          const videoBlob = new Blob([uint8Buffer], { type: 'video/mp4' });
  
          // Create an object URL from the Blob
          const videoUrl = window.URL.createObjectURL(videoBlob);
          await handleUploadToFirebase(
            { key: {key: `record/${key}`}, fileName: key },
            taskId,
          );
          setUploading(false);
          setRecordVideo(false);
          return videoUrl;
  
        } catch (error) {
          console.error('Error fetching video:', error);
        }
      };
  

  const handleStopAudio = async () => {
    if (zegoInstance) {
      try {
        if (permissions && permissions.audioStopped) {
          await zegoInstance.mutePublishStreamAudio(localStream, false);
          setPermissions((prev) => ({ ...prev, audioStopped: false }));
        } else {
          setPermissions((prev) => ({ ...prev, audioStopped: true }));
          await zegoInstance.mutePublishStreamAudio(localStream, true);
        }
      } catch (error) {
        console.error("Error occurred while toggling audio:", error);
      }
    }
  };

  const handleShare = async () => {
    if (!permissions.shareStopped) {
      const screenStream = await zegoInstance.createZegoStream({
        screen: {
          videoBitrate: 1500,
          audio: true,
          video: {
            quality: 2,
            frameRate: 15,
          },
        },
      });
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const screenStreamId = streamIdNum + Date.now();
      setPermissions((prev) => ({ ...prev, shareStopped: true }));
      setScreenStreamId(String(screenStreamId));
      setScreenStream(screenStream);
      zegoInstance.startPublishingStream(String(screenStreamId), localStream);
      zegoInstance.on("screenSharingEnded", () => {
        zegoInstance.stopPublishingStream(String(screenStreamId));
        zegoInstance.destroyStream(localStream);
        setPermissions((prev) => ({ ...prev, shareStopped: false }));
      });
    } else {
      const screenStreamId = streamIdNum + Date.now();
      zegoInstance.stopPublishingStream(String(screenStreamId));
      zegoInstance.destroyStream(localStream);
      setPermissions((prev) => ({ ...prev, shareStopped: false }));
    }
  };

  const StreamSrcObject = (stream: any, videoElement: any) => {
    if (videoElement && stream instanceof MediaStream) {
      try {
        videoElement.srcObject = stream;
      } catch (error) {
        console.error("Error setting stream:", error);
      }
    }
  };

  const handleOnCancel = () => {
    setRecordVideo(false);
    setStopTimer(false);
    if (localStream && zegoInstance && streamId) {
      zegoInstance.stopPublishingStream(streamId);
      zegoInstance.destroyStream(localStream);
    }
  };

  const handleUpload = (text: string) => {
    if (text == recordVideoKeys?.start) {
      permissions?.recordingStarted ? handlePause() : handleStart();
    } else if (text == recordVideoKeys?.cam) {
      handleStopVideo();
    } else if (text == recordVideoKeys?.mic) {
      handleStopAudio();
    } else if (text == recordVideoKeys?.share) {
      handleShare();
    } else {
      return;
    }
  };

  const getColor = (text: string) => {
    if (text == recordVideoKeys?.start && permissions?.recordingStarted) {
      return "bg-[red]";
    } else if (text == recordVideoKeys?.cam && permissions?.videoStopped) {
      return "bg-[red]";
    } else if (text == recordVideoKeys?.mic && permissions?.audioStopped) {
      return "bg-[red]";
    } else if (text == recordVideoKeys?.share && permissions?.shareStopped) {
      return "bg-[red]";
    } else {
      return;
    }
  };

  const handleChangeIcon = (text: string, icon: any, icon1: any) => {
    if (text == recordVideoKeys?.start) {
      return permissions?.recordingStarted ? icon : icon1;
    } else if (text == recordVideoKeys?.cam) {
      return !permissions?.videoStopped ? icon : icon1;
    } else if (text == recordVideoKeys?.mic) {
      return !permissions?.audioStopped ? icon : icon1;
    } else if (text == recordVideoKeys?.share) {
      return !permissions?.shareStopped ? icon : icon1;
    } else {
      return;
    }
  };

   const handleUploadToDB = async () => {
    setStopTimer(true);
    setUploading(true);
    setProgress(0);
    // Step 2: Fetch File URL
    const fileUrl = await getFileUrl();
    setProgress(30);

    if (fileUrl) {
      const retryInterval = 5000; // Retry every 5 seconds
      let progress = 30; // Start progress after stopping recording

      const retryDownload = async () => {
        try {
          const awsUrl = await downloadVideoFromURL(fileUrl);
          if (awsUrl) {
            // Set progress to 100% upon successful download
            setProgress(100);
            setStopTimer(false);

            return awsUrl; // Exit the retry mechanism
          }
        } catch (error) {
          console.error("Error during download attempt:", error);
        }

        // Increment progress slightly on every retry
        progress = Math.min(progress + 5, 90); // Cap progress at 90% until completion
        setProgress(progress);

        // Retry after the interval
        setTimeout(retryDownload, retryInterval);
      };

      retryDownload(); // Start the retry mechanism
    } else {
      // Update progress after recording stops
      setProgress(30);
    }
    };
  
    const handleUploadToFirebase = async (data: any, uid: any) => {
      try {
        const options: any = {
          assessmentId: assessmentId,
          setId: setId,
          candidateId: candidate_id,
          endpoint: `${FIREBASE_URL?.ENDPOINT?.PRACTICALS}/${currentQuestion?.id}/${uid}`,
        };
        const firebaseUrl = getFirebaseUrl(options);
        await handleFirebaseOperation(firebaseUrl, firebaseMethodKeys.PUT, data);
      } catch (error) {
        console.log("error", error);
      }
    };
  return (
    <div>
      {recordVideo && (
        <ModalComponent
          title={`Record - ${formattedDate}`}
          handleOnCancel={() => {
            handleOnCancel();
          }}
          className="uploadModal"
          titleClass={"text-primaryText text-[20px] font-semibold"}
          closeIcon={true}
          isAssessment={false}
          questionModal={true}
          width={650}
          isModalOpen={recordVideo}
          uploadButtonName1={buttonTitle?.cancel}
          uploadButtonName2={
            !uploading ? buttonTitle.upload : buttonTitle.uploading
          }
          modalButtonClick={handleUploadToDB}
          content={
            <div className="mt-[20px]">
              {localStream ? (
                <video
                  id="local-video"
                  className="h-[377px] w-[600px]"
                  autoPlay={true}
                  muted={true}
                  playsInline={true}
                  ref={(video) => StreamSrcObject(localStream.stream, video)}
                />
              ) : (
                <div className="h-[377px] w-[600px] flex justify-center items-center">
                  <Spin
                    indicator={
                      <LoadingOutlined
                        style={{
                          fontSize: 50,
                          color: "#22c95cfa",
                        }}
                        spin
                      />
                    }
                  />
                </div>
              )}

              <div className="flex gap-3 mt-3 justify-center font-semibold">
                {recordVideoButtons.map((button: any, index: number) => (
                  <div
                    key={index}
                    onClick={() => {
                      handleUpload(button.label);
                    }}
                    className="flex flex-col items-center">
                    <div
                      className={classNames(
                        getColor(button.label),
                        "bg-[gray] w-[40px] h-[40px] flex justify-center items-center rounded-md",
                      )}>
                      {handleChangeIcon(
                        button.label,
                        button?.icon,
                        button?.icon2,
                      )}
                    </div>
                    <div>{permissions?.recordingStarted ? button.label2 : button.label}</div>
                  </div>
                ))}
              </div>

              <div>
                {uploading && <div className="max-h-[100px] overflow-y-auto">
                  <div style={{ marginTop: 16 }}>
                    <Progress percent={progress} />
                  </div>
                </div>}
              </div>
            </div>
          }
        />
      )}
    </div>
  );
};

export default RecordVideoModal;
