import React, { useState } from "react";
import { Form, Input } from "antd";
import { useNavigate } from "react-router-dom";
import Card from "@/components/elements/Card";
import { unauthenticatedAxios } from "@/utils/axiosInterceptor";
import { FIREBASE_URL, URL } from "@/utils/service-urls.ts";
import Logo from "@/components/logo";
import {
  buttonTitle,
  contentDivText,
  placeHolders,
  localStorageKeys,
  messageEnum,
  firebaseMethodKeys,
} from "@/constants/constants";
import { useAuth } from "@/contexts/authContext";
import { useDispatch } from "react-redux";
import {
  getFirebaseUrl,
  getIntlErrorMessage,
  handleMessage,
  questionPaperMapping,
} from "@/utils/functions.ts";
import { handleFirebaseOperation } from "@/firebase";

const LoginWithAccessCode = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { login, currentUser, setLoggedIn, setAssessmentIdCxt } = useAuth();
  const redirectPath = sessionStorage.getItem("redirectPath");



  const onFinish = async (values) => {
    setLoading(true);
    login()
      .then((data) => {
        sessionStorage.setItem(
          localStorageKeys.firebaseAuthCreds,
          JSON.stringify(data.user),
        );

        const dataToSend = {
          requestBody: {
            passcode: values.accessCode,
            linkParams: values.assessmentUrl.split('assessment/')[1],
            userId: data?.user.uid,
            sys_info: {
              browser: "chrome",
              ipaddress: "x.xx",
              latitude: "0",
              longitude: "0",
            },
          },
        };

        unauthenticatedAxios
          .post(URL.ACCOUNT.AUTHENTICATE, dataToSend)
          .then(async (response) => {
            if (response?.status === 200) {
              setLoading(false);
              localStorage.setItem(localStorageKeys.loggedIn, "true");
              sessionStorage.setItem(
                localStorageKeys.jwt,
                response.data.responseBody.jwtToken,
              );

              localStorage.setItem(
                localStorageKeys.candidateId,
                response.data.responseBody.candidate_id,
              );
              localStorage.setItem(
                localStorageKeys.setId,
                response.data.responseBody.set_id,
              );
              localStorage.setItem(
                localStorageKeys.orgId,
                response.data.responseBody.organization_id,
              );
              localStorage.setItem(
                localStorageKeys.link,
                response.data.responseBody.link,
              );
              localStorage.setItem(
                localStorageKeys.assessmentId,
                response.data.responseBody.assessment_id,
              );
              localStorage.setItem(
                localStorageKeys.adjustTime,
                response.data.responseBody.adjust_time_by,
              );

              const assessmentDetails = JSON.stringify({
                set_id: response.data.responseBody.set_id,
                question_paper_details: response.data.responseBody.question_paper_details,
                assessment_name: response.data.responseBody.assessment_name,
                rules: response.data.responseBody.rules,
                full_name: response.data.responseBody.full_name,
                username: response.data.responseBody.username,
                email: response.data.responseBody.email,
                instructions: response.data.responseBody.instructions,
              });
              localStorage.setItem(localStorageKeys.assessmentDetails, assessmentDetails);

              localStorage.setItem(localStorageKeys?.fromCandidateDashboard, "true");

              dispatch({
                type: "SET_CANDIDATE_ID",
                payload: response.data.responseBody.candidate_id,
              });
              dispatch({
                type: "SET_ASSESSMENT_ID",
                payload: response.data.responseBody.assessment_id,
              });
              dispatch({
                type: "SET_ASSESSMENT_SET_ID",
                payload: response.data.responseBody.set_id,
              });
              dispatch({
                type: "SET_ASSESSMENT_DETAILS",
                payload: JSON.parse(assessmentDetails),
              });

              const questionPaper = await handleFirebaseOperation(
                `assessments/${response.data.responseBody.assessment_id}/sets/${response.data.responseBody.set_id}/question_paper`,
                firebaseMethodKeys.GET,
              );
              questionPaperMapping(questionPaper);

              try {
                const options = {
                  assessmentId: response.data.responseBody.assessment_id,
                  setId: response.data.responseBody.set_id,
                  candidateId: response.data.responseBody.candidate_id,
                  endpoint: `${FIREBASE_URL?.ENDPOINT?.EVIDENCES}/${FIREBASE_URL?.ENDPOINT?.ORG_ID}`,
                };
                const firebaseUrl = getFirebaseUrl(options);
                handleFirebaseOperation(
                  firebaseUrl,
                  firebaseMethodKeys.PUT,
                  response.data.responseBody.organization_id,
                );
                setLoggedIn();
                setAssessmentIdCxt(response.data.responseBody.assessment_id);

                const restrictedPaths = ["/login", "/"];
                const isValidRedirectPath = redirectPath && !restrictedPaths.includes(redirectPath);
                const navigateTo = isValidRedirectPath
                  ? redirectPath
                  : `/${response.data.responseBody.link}`;

                navigate(navigateTo);
              } catch (error) {
                console.error("Error in Firebase operation:", error);
              }
            } else {
              console.error("Authentication failed with status:", response?.status);
              const errorMsg = getIntlErrorMessage("LOGIN", "INVALID_CREDENTIALS");
              handleMessage(messageEnum?.error, errorMsg);
              setLoading(false);
            }
          })
          .catch((error) => {
            console.error("Error during authentication API call:", error);
            const errorMsg = getIntlErrorMessage("LOGIN", "INVALID_CREDENTIALS");
            handleMessage(messageEnum?.error, errorMsg);
            setLoading(false);
          });
      })
      .catch((error) => {
        console.error("Error during login process:", error.message);
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="login-page">
      <div className="h-screen mt-[130px]">
        <Card className="max-w-lg overflow-hidden shadow-lg mx-auto custom-card elevation">
          <div className="text-center">
            <Logo heroheader={true} />
            <hr className="my-4 border-gray" />
          </div>
          <div className="flex flex-col justify-center">
            <div className="px-10">
              <Form
                form={form}
                name="basic"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                preserve={false}
              >
                <Form.Item
                  name="assessmentUrl"
                  rules={[
                    {
                      required: true,
                      message: contentDivText?.enterAssessmentUrl,
                    },
                  ]}
                >
                  <Input
                    placeholder={placeHolders?.assessmentUrl}
                    className="relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  />
                </Form.Item>
                <Form.Item
                  name="accessCode"
                  rules={[
                    {
                      required: true,
                      message: contentDivText?.enterAccessCode,
                    },
                  ]}
                >
                  <Input
                    placeholder={placeHolders?.accessCode}
                    className="relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  />
                </Form.Item>
                <Form.Item>
                  <div>
                    <button
                      type="submit"
                      className="group relative flex w-full justify-center rounded-md border border-transparent bg-[#22c95cfa] focus:bg-[#22c95cfa] mt-4 py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 text-[white]"
                    >
                      <span>
                        {loading ? buttonTitle?.signingIn : buttonTitle?.signIn}
                      </span>
                    </button>
                  </div>
                </Form.Item>
                <Form.Item>
                <div className="mt-4 text-center">
  <button
    type="button"
    onClick={() => navigate('/candidate/login')}
    className="text-sm font-bold  text-black bg-transparent border-none cursor-pointer hover:underline"
  >
    Login With Email/Username
  </button>
</div>
                </Form.Item>
              </Form>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default LoginWithAccessCode;