import React, { useContext, useEffect, useState } from "react";
import { ItemStateContext } from "@/contexts/questionContext";
import LeftComponentForAllQuestions from "@/components/leftComponentForAllQuestions";
import LeftComponentForSingleQuestion from "@/components/leftComponentForSingleQuestion";
import { questionType, localStorageKeys } from "@/constants/constants";
import { LeftComponentProps } from "@/types/componentTypes";
import AllQuestionsInOnePage from "./allQuestionsInOnePage";

const LeftComponent: React.FC<LeftComponentProps> = ({
  handleInputBoxChange,
  selectedLanguage,
  currentQuestion,
  reviewButton,
}) => {
  const {
    setResponses,
    responses,
    showAllQuestions,
    setCurrentQuestionIndex,
    questions,
    allQuestionsInPage,
  }: any = useContext(ItemStateContext);

  const [autoEvaluate, setAutoEvaluate] = useState(false);
  useEffect(() => {
    const storedValue = localStorage.getItem(
      localStorageKeys.assessment_details,
    );
    let assessment_details: any;
    if (storedValue !== null) {
      assessment_details = JSON.parse(storedValue);
      setAutoEvaluate(assessment_details?.rules?.showResultsToCandidates);
    } else {
      assessment_details = null;
    }
  }, []);

  const handleCheckboxChange = (
    optionText: string,
    index: number,
    id?: number,
    questionId?: number,
  ) => {
    setCurrentQuestionIndex(index);

    const updatedResponses = { ...responses };

    if (questions?.[index]?.question_type === questionType.scenarioType) {
      let scenarioQuestion =
        updatedResponses?.[selectedLanguage]?.[index]?.scenarioResponse;

      if (!scenarioQuestion) {
        scenarioQuestion = {
          checkboxResponse: [],
          radioResponse: scenarioQuestion?.radioResponse,
        };
      }

      const checkboxData = scenarioQuestion?.checkboxResponse || [];

      if (checkboxData.includes(optionText)) {
        scenarioQuestion.checkboxResponse = checkboxData?.filter(
          (item: any) => item !== optionText,
        );
      } else {
        checkboxData.push(optionText);
        scenarioQuestion.checkboxResponse = checkboxData;
      }

      updatedResponses[selectedLanguage][index].scenarioResponse =
        scenarioQuestion;
      updatedResponses[selectedLanguage][index].questionId = questionId;
    } else {
      const checkBoxQuestion =
        updatedResponses?.[selectedLanguage]?.[index]?.checkboxResponse || [];
      if (checkBoxQuestion.includes(id)) {
        const updatedCheckboxResponse = checkBoxQuestion.filter(
          (item: any) => item !== id,
        );
        updatedResponses[selectedLanguage][index].checkboxResponse =
          updatedCheckboxResponse;
        updatedResponses[selectedLanguage][index].questionId = questionId;
      } else {
        checkBoxQuestion.push(id);
        updatedResponses[selectedLanguage][index].checkboxResponse =
          checkBoxQuestion;
        updatedResponses[selectedLanguage][index].questionId = questionId;
      }
    }

    // Set reviewed to false for the updated response
    updatedResponses[selectedLanguage][index].reviewed = false;

    setResponses(updatedResponses);
  };

  const handleRadioChange = (
    option: string,
    index: number,
    id?: number,
    questionId?: number,
  ) => {
    if (Object.keys(responses).length > 0) {
      const updatedResponses = { ...responses };

      if (questions?.[index]?.question_type === questionType?.scenarioType) {
        updatedResponses[selectedLanguage][
          index
        ].scenarioResponse.radioResponse = option;
        updatedResponses[selectedLanguage][index].questionId = questionId;
      } else {
        updatedResponses[selectedLanguage][index].radioResponse = id;
        updatedResponses[selectedLanguage][index].questionId = questionId;
      }

      // Set reviewed to false for the updated response
      updatedResponses[selectedLanguage][index].reviewed = false;

      setResponses(updatedResponses);
    }
  };
  const handleTextAreaChange = (
    index: number,
    text: string,
    questionId?: number,
  ) => {
    if (index) {
      setCurrentQuestionIndex(index);
    }
    setResponses((prev) => {
      const updatedResponses = { ...prev };
      updatedResponses[selectedLanguage][index].textAreaResponse = text;
      updatedResponses[selectedLanguage][index].questionId = questionId;
      // Set reviewed to false for the updated response
      updatedResponses[selectedLanguage][index].reviewed = false;
      return updatedResponses;
    });
  };
  return (
    <div className="h-full">
      {allQuestionsInPage ? (
        <AllQuestionsInOnePage
          // handleInputBoxChange={handleInputBoxChange}
          selectedLanguage={selectedLanguage}
          // currentQuestion={currentQuestion}
          handleCheckboxChange={handleCheckboxChange}
          handleRadioChange={handleRadioChange}
          handleTextAreaChange={handleTextAreaChange}
        />
      ) : !showAllQuestions ? (
        <div>
          <LeftComponentForSingleQuestion
            handleInputBoxChange={handleInputBoxChange}
            selectedLanguage={selectedLanguage}
            currentQuestion={currentQuestion}
            handleCheckboxChange={handleCheckboxChange}
            handleRadioChange={handleRadioChange}
            handleTextAreaChange={handleTextAreaChange}
          />
        </div>
      ) : (
        <LeftComponentForAllQuestions
          handleInputBoxChange={handleInputBoxChange}
          selectedLanguage={selectedLanguage}
          handleCheckboxChange={handleCheckboxChange}
          handleRadioChange={handleRadioChange}
          handleTextAreaChange={handleTextAreaChange}
          reviewButton={reviewButton}
          ourResponse={autoEvaluate}
        />
      )}
    </div>
  );
};

export default LeftComponent;
