import React, { FC, useContext } from "react";
import { Steps } from "antd";
import { SlCamera } from "react-icons/sl";
import MicrophoneStatus from "@/components/microPhoneStatus";
import BroswerChecker from "@/components/browserCheck";
import WebcamStatusChecker from "@/components/webcamStatusCheck";
import LocationComponent from "@/components/locationAccess";
import { LuScreenShare } from "react-icons/lu";
import { FaMicrophone } from "react-icons/fa";
import { GiNetworkBars } from "react-icons/gi";
import { GoBrowser } from "react-icons/go";
import { FaLocationDot, FaOpera } from "react-icons/fa6";
import ScreenSharingCheck from "@/components/screenShareStatus";
import NetworkStatus from "@/components/networkStatus";
import { ItemStateContext } from "@/contexts/questionContext";
import OsChecker from "@/components/osName";
import VpnCheck from "@/components/vpnCheck";
import { SiOpenvpn } from "react-icons/si";
import { osTypes, stepsIconStyles, systemChecks } from "@/constants/constants";
import { StepProps, StepsComponentProps } from "@/types/componentTypes";
const StepsComponent: FC<StepsComponentProps> = ({
  currentStep,
  currentLocation,
  setCurrentLocation,
  handleStepChange,
  isSharingRequired,
  isVPNRequired,
}) => {
  const { allPermissions }: any = useContext(ItemStateContext);

  const getStatus = (condition: any): string => {
    if (condition) return "finish";
    return "error";
  };

  const getDeniedMessage = (condition, success, failure) => {
    if (condition) {
      return success;
    }
    return failure;
  };

  const steps: StepProps[] = [
    {
      resultantDataIcon: (
        <GiNetworkBars
          style={{
            color: stepsIconStyles?.stepsIconColor,
            fontWeight: stepsIconStyles?.stepsIconFontWeight,
          }}
        />
      ),
      title: systemChecks?.network?.title,
      resultTitle: systemChecks?.network?.resultTitle,
      subText1: systemChecks?.network?.subText1,
      subText2: `${allPermissions?.networkSpeed} Mbps`,
      deniedMessage: getDeniedMessage(
        !allPermissions?.isOnline,
        systemChecks?.network?.deniedMessage,
        "",
      ),
      content: (
        <NetworkStatus
          onAllow={() => handleStepChange(currentStep, steps?.length)}
        />
      ),
      status: getStatus(allPermissions.isOnline),
    },
    {
      resultantDataIcon: (
        <FaOpera
          style={{
            color: stepsIconStyles?.stepsIconColor,
            fontWeight: stepsIconStyles?.stepsIconFontWeight,
          }}
        />
      ),
      title: systemChecks?.os?.title,
      subText1: systemChecks?.os?.subText1,
      subText2: `${allPermissions?.osName?.family}`,
      resultTitle: systemChecks?.os?.resultTitle,
      deniedMessage: getDeniedMessage(
        !osTypes.includes(allPermissions?.osName?.family),
        systemChecks?.os?.deniedMessage,
        "",
      ),
      content: (
        <OsChecker
          onAllow={() => handleStepChange(currentStep, steps?.length)}
        />
      ),
      status: getStatus(osTypes.includes(allPermissions.osName?.family)),
    },
    {
      resultantDataIcon: (
        <GoBrowser
          style={{
            color: stepsIconStyles?.stepsIconColor,
            fontWeight: stepsIconStyles?.stepsIconFontWeight,
          }}
        />
      ),
      title: systemChecks?.browser?.title,
      subText1: systemChecks?.browser?.subText1,
      subText2: `${allPermissions?.browserName}`,
      resultTitle: systemChecks?.browser?.resultTitle,
      deniedMessage: getDeniedMessage(
        !allPermissions?.browserCheck && allPermissions?.browserCheck !== null,
        systemChecks?.browser?.deniedMessage,
        "",
      ),
      content: (
        <BroswerChecker
          onAllow={() => handleStepChange(currentStep, steps?.length)}
        />
      ),
      status: getStatus(
        allPermissions?.browserCheck && allPermissions?.browserCheck !== null,
      ),
    },
    {
      resultantDataIcon: (
        <FaLocationDot
          style={{
            color: stepsIconStyles?.stepsIconColor,
            fontWeight: stepsIconStyles?.stepsIconFontWeight,
          }}
        />
      ),
      title: systemChecks?.location?.title,
      resultTitle: systemChecks?.location?.resultTitle,
      subText1: systemChecks?.location?.subText1,
      subText2: `${currentLocation?.address ?? ""}`,
      deniedMessage: getDeniedMessage(
        !allPermissions?.locationEnabled &&
          allPermissions?.locationEnabled !== null,
        systemChecks?.location?.deniedMessage,
        "",
      ),
      content: (
        <LocationComponent
          onAllow={() => handleStepChange(currentStep, steps?.length)}
          setCurrentLocation={setCurrentLocation}
        />
      ),
      status: getStatus(
        allPermissions?.locationEnabled &&
          allPermissions?.locationEnabled !== null,
      ),
    },

    ...(isVPNRequired
      ? [
          {
            resultantDataIcon: (
              <SiOpenvpn
                style={{
                  color: stepsIconStyles?.stepsIconColor,
                  fontWeight: stepsIconStyles?.stepsIconFontWeight,
                }}
              />
            ),
            title: systemChecks?.vpn?.title,
            resultTitle: systemChecks?.vpn?.resultTitle,
            subText1: systemChecks?.vpn?.subText1,
            subText2:
              allPermissions?.vpnDisabled &&
              allPermissions?.vpnDisabled !== null &&
              "No VPN Detected",
            deniedMessage: getDeniedMessage(
              !allPermissions?.vpnDisabled &&
                allPermissions?.vpnDisabled !== null,
              systemChecks?.vpn?.deniedMessage,
              "",
            ),
            content: (
              <VpnCheck
                onAllow={() => handleStepChange(currentStep, steps?.length)}
                setCurrentLocation={setCurrentLocation}
              />
            ),
            status: getStatus(
              allPermissions?.vpnDisabled &&
                allPermissions?.vpnDisabled !== null,
            ),
          },
        ]
      : []),
    {
      resultantDataIcon: (
        <SlCamera
          style={{
            color: stepsIconStyles?.stepsIconColor,
            fontWeight: stepsIconStyles?.stepsIconFontWeight,
          }}
        />
      ),
      title: systemChecks?.webcam?.title,
      resultTitle: systemChecks?.webcam?.resultTitle,
      subText1: "",
      subText2: "",
      deniedMessage: getDeniedMessage(
        !allPermissions?.webcamEnabled &&
          allPermissions?.webcamEnabled !== null,
        systemChecks?.webcam?.deniedMessage,
        "",
      ),
      content: (
        <WebcamStatusChecker
          onAllow={() => handleStepChange(currentStep, steps?.length)}
        />
      ),
      status: getStatus(
        allPermissions?.webcamEnabled && allPermissions?.webcamEnabled !== null,
      ),
    },

    {
      resultantDataIcon: (
        <FaMicrophone
          style={{
            color: stepsIconStyles?.stepsIconColor,
            fontWeight: stepsIconStyles?.stepsIconFontWeight,
          }}
        />
      ),
      title: systemChecks?.mic?.title,
      resultTitle: systemChecks?.mic?.resultTitle,
      subText1: "",
      subText2: "",
      deniedMessage: getDeniedMessage(
        !allPermissions?.microphoneEnabled &&
          allPermissions?.microphoneEnabled !== null,
        systemChecks?.mic?.deniedMessage,
        "",
      ),
      content: (
        <MicrophoneStatus
          onAllow={() => handleStepChange(currentStep, steps?.length)}
        />
      ),
      status: getStatus(
        allPermissions?.microphoneEnabled &&
          allPermissions?.microphoneEnabled !== null,
      ),
    },

    ...(isSharingRequired
      ? [
          {
            resultantDataIcon: (
              <LuScreenShare
                style={{
                  color: stepsIconStyles?.stepsIconColor,
                  fontWeight: stepsIconStyles?.stepsIconFontWeight,
                }}
              />
            ),
            title: systemChecks?.screenShare?.title,
            resultTitle: systemChecks?.screenShare?.resultTitle,
            subText1: "",
            subText2: systemChecks?.screenShare?.subText2,
            deniedMessage: getDeniedMessage(
              !allPermissions?.screenSharingAllowed &&
                allPermissions?.screenSharingAllowed !== null,
              systemChecks?.screenShare?.deniedMessage,
              "",
            ),
            content: (
              <ScreenSharingCheck
                onAllow={() => handleStepChange(currentStep, steps?.length)}
              />
            ),
            status: getStatus(allPermissions?.screenSharingAllowed),
          },
        ]
      : []),
  ];
  return (
    <div style={{ maxWidth: "800px" }} className="steps-titles">
      <Steps
        current={currentStep}
        size="small"
        style={{ textAlign: "left" }}
        labelPlacement="vertical"
        items={steps.map((step, index) => ({
          key: index,
          title: step.title,
          status: step?.status,
        }))}
      />

      <div>{steps[currentStep].content}</div>
      <div className="w-[500px] h-[200px] bg-checkGreen rounded-[15px] flex justify-between items-center p-[20px] pl-[30px]   mt-[30px]">
        <div className="flex flex-col justify-center space-y-[10px]">
          <div className="text-[20px] font-semibold text-primaryText">
            {steps[currentStep]?.resultTitle}
          </div>
          {steps[currentStep]?.deniedMessage && (
            <div className="text-[12px] text-deniedText">
              {steps[currentStep]?.deniedMessage}
            </div>
          )}
          {steps[currentStep]?.subText2 &&
            !steps[currentStep]?.deniedMessage && (
              <div className="text-[14px] font-normal">
                {steps[currentStep]?.subText1}
              </div>
            )}
          {steps[currentStep]?.subText2 &&
            !steps[currentStep]?.deniedMessage && (
              <div className="text-[18px] font-normal text-primary">
                {steps[currentStep]?.subText2}
              </div>
            )}
        </div>
        {steps[currentStep]?.resultantDataIcon && (
          <div className="mr-[20px] bg-white p-[30px] rounded-[100%] text-[40px] ml-[20px]">
            {steps[currentStep]?.resultantDataIcon}
          </div>
        )}
      </div>
    </div>
  );
};

export default StepsComponent;
