import React, { useContext } from "react";
import classNames from "classnames";
import { questionType, totalParts } from "@/constants/constants";
import { QuestionItemProps } from "@/types/componentTypes";
import { ItemStateContext } from "@/contexts/questionContext";

const QuestionItem: React.FC<QuestionItemProps> = ({
  question,
  index,
  language,
  responses,
  showQuestion,
  isFillInTheBlanksEmpty,
  isMatchingEmpty,
  isScenarioBasedQuestionEmpty,
}) => {
  const { selectedPart, vivaResponse, activityResponse }: any =
    useContext(ItemStateContext);
  const getColorClass = () => {
    const colorClass =
    (question?.question_category === "viva"
      ? !vivaResponse[index]?.visited
      : (question?.question_type === questionType.trueOrfalseType &&
        responses?.[language]?.[index]?.radioResponse === "") ||
      (question?.question_type === questionType.multipleChoiceType &&
        responses?.[language]?.[index]?.radioResponse === "") ||
      (question?.question_type === questionType.multiSelectType &&
        responses?.[language]?.[index]?.checkboxResponse?.length === 0) ||
      (question?.question_type === questionType.matchingType &&
        isMatchingEmpty(responses?.[language]?.[index])) ||
      (question?.question_type === questionType.filltype &&
        isFillInTheBlanksEmpty(responses?.[language]?.[index])) ||
      (question?.question_type === questionType.subJectiveType &&
        responses?.[language]?.[index]?.textAreaResponse === "") ||
      (question?.question_type === questionType.scenarioType &&
        isScenarioBasedQuestionEmpty(responses?.[language]?.[index])) ||
      (question?.question_type.includes(questionType.activityType) &&
        Object.keys(activityResponse[index]?.uploadedData || {}).length ===
          0));
    if (selectedPart === totalParts[0]) {
      return colorClass ? "bg-[red]" : "bg-primary";
    } else if (selectedPart === totalParts[1]) {
      return colorClass ? "bg-[red]" : "bg-primary";
    } else if (selectedPart === totalParts[2]) {
      return colorClass ? "bg-[red]" : "bg-primary";
    }
  };
  return (
    <div>
      <button
        className={classNames(
          getColorClass(),
          "w-[40px] h-[40px] flex justify-center items-center cursor-pointer text-[white]",
        )}
        onClick={() => showQuestion(index, language)}
        style={{
          backgroundColor:
            selectedPart === totalParts[0]
              ? responses?.[language]?.[index]?.reviewed
                ? "blue"
                : ""
              : selectedPart === totalParts[1]
                ? activityResponse?.[index]?.reviewed
                  ? "blue"
                  : ""
                : selectedPart === totalParts[2]
                  ? vivaResponse?.[index]?.reviewed
                    ? "blue"
                    : ""
                  : "",
        }}>
        {index + 1}
      </button>
    </div>
  );
};

export default QuestionItem;
