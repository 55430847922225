export const URL = {
  ACCOUNT: {
    LOGIN: "/login",
    AUTHENTICATE: "/candidates/authenticate/assessment",
    AUTHENTICATE_STAKEHOLDERS: "/candidates/authenticate/stakeholder",
    PASSWORD_AUTHENTICATE: "/candidates/authenticate/1.1.0",
    LOGOUT: "/candidates/logout",
    GET_ZEGO_TOKEN: "/candidates/conference",
    TOKEN: "/token",
    CANDIDATE_LOGIN: '/candidate/login'
  },
  AUXILIARYPATHS: {
    DASHBOARD: "/dashboard",
    ASSESSMENT: "/assessment",
    ID: "/id",
  },
  QUESTIONPAPERDETAILS: {
    EVALUTION_CRITERIA: "/get/questionPaperEvaluationList"
  },
  ASSESSMENT: {
    CANDIDATE_ASSESSMENT: "/candidates/assessments",
    CANDIDATE_ANSWERS:"/candidates/answers",
    CANDIDATE_SET: "sets",
    FEEDBACK_QUESTIONS: "/feedback/questions",
    INVIGILATOR_FEEDBACK_QUESTIONS: "/invigilator/feedback/questions",
    INVIGILATOR_FEEDBACK_RESPONSE: "/invigilator/feedback/response",
    CANDIDATE_FEEDBACK_RESPONSE: "/feedback/response",
    CANDIDATE_SAMPLE: "/sample",
    ASSESSMENT_CANDIDATE: "candidates/assessments",
    STAKE_HOLDERS: "/stakeholders",
    CANDIDATES_ASSESSMENT: "candidates/assessments",
    CANDIDATES: "candidates",
    CANDIDATE_STAKEHOLDER_ASSESSMENT: "/candidates/assessments",
    STAKEHOLDER_ASSESSMENT: "/candidates/stakeholders/assessment",
    LOGS: "/logs",
    CANDIDATE: "candidate",
    STATUS: "status",
    VIOLATIONS: "candidate/violations",
    CANDIDATE_ASSESSMENTS: "candidates/assessments",
    FINAL_EVALUATION: "final-evaluation",
    ASSESSMENT_RESULTS:"candidates/assessments_results",
    EVIDENCES:"candidates/evidences"
  },

  SERVICES: {
    UPLOAD: "/candidates/upload",
    DELETE: "/candidates/delete",
    DOWNLOAD: "/candidates/download",
    EVALUATIONS: "evaluations",
  },
  VIVA: {
    ZEGO_CLOUD_TOKEN: "/candidates/conference",
    TOKEN: "/token",
  },
  EXTERNAL_URLS: {
    VPN_DETECTION_URL: `https://api.ipdata.co?api-key=${process.env.REACT_APP_VPN_API_KEY}`,
    LOCATION_LOOKUP_URL:
      "https://nominatim.openstreetmap.org/reverse?format=jsonv2",
  },
};

export const FIREBASE_URL = {
  ITEM_URL: {
    ASSESSMENT: "assessments",
    CANDIDATE: "candidates",
    SET: "sets",
  },
  ENDPOINT: {
    ANSWERS: "answers",
    LOGS: "logs",
    EVIDENCES: "evidences",
    IMAGES: "images",
    VIDEO: "video",
    STATE: "state",
    FEEDBACK: "feedback",
    VIOLATIONS: "violations",
    ORG_ID: "org_id",
    EXAM_STATUS: "exam_status",
    PRACTICALS: "practicals",
  },
};
