import { useContext, useEffect, useState } from "react";
import platform from "platform";
import { ItemStateContext } from "@/contexts/questionContext";
import { BrowserCheckerProps } from "@/types/componentTypes";

const STORAGE = "storage";
const LOCAL_STORAGE = "localStorage";
const SESSION_STORAGE = "sessionStorage";
const CANVAS = "canvas";

function BroswerChecker({ onAllow }: Readonly<BrowserCheckerProps>) {
  const [isIncognito, setIsIncognito] = useState<any>(null);
  const { setAllPermissions }: any = useContext(ItemStateContext);
  useEffect(() => {
    setAllPermissions((prev) => ({
      ...prev,
      browserName: platform?.name,
    }));

    if (STORAGE in navigator) {
      navigator.storage.estimate().then((quota: any) => {
        if (quota.quota / 1000000 < 1097) {
          setIsIncognito(true);
        } else {
          setIsIncognito(false);
        }
      });
    } else {
      setIsIncognito(true);
    }

    const isLocalStorageSupported =
      LOCAL_STORAGE in window && window.localStorage !== null;
    const isSessionStorageSupported =
      SESSION_STORAGE in window && window.sessionStorage !== null;
    const isCanvasSupported = !!document.createElement(CANVAS).getContext;

    const allFeaturesSupported =
      isLocalStorageSupported &&
      isSessionStorageSupported &&
      isCanvasSupported &&
      !isIncognito;

    setAllPermissions((prev) => ({
      ...prev,
      browserCheck: allFeaturesSupported,
      osName: platform?.os,
    }));
    onAllow();
  }, []);

  return <div></div>;
}

export default BroswerChecker;
