import React, { useEffect, useState } from "react";
import { authenticatedAxios } from "@/utils/axiosInterceptor";
import { CustomAxiosRequestConfig } from "@/types/functionTypes";
import { getIntlErrorMessage, handleMessage } from "@/utils/functions";
import { messageEnum, localStorageKeys } from "@/constants/constants";
import { URL } from "@/utils/service-urls";
import * as XLSX from "xlsx";
import { MdOutlineFileDownload } from "react-icons/md";
const AssessmentDetailsHeader = ({ questionPaperDetails, assessment_details, candidate_details }) => {
 type Candidate = {
  candidate_id: number; // or string, depending on the data type of candidate_id
  // other properties of candidate
};

const getDistinctCandidates = (candidates: Candidate[] = []): Candidate[] => {
  const uniqueCandidates = new Map<number, Candidate>(); // Use number if candidate_id is a number, or string if it's a string

  if (!Array.isArray(candidates)) {
    console.error("Expected 'candidates' to be an array, but got:", typeof candidates);
    return [];
  }

  candidates.forEach((candidate) => {
    if (candidate && candidate.candidate_id && !uniqueCandidates.has(candidate.candidate_id)) {
      uniqueCandidates.set(candidate.candidate_id, candidate);
    }
  });

  return Array.from(uniqueCandidates.values());
};
  const qpDetails = {
    qpCode: "QP Code",
    qpCodeValue: "PHY10B",
    qpTitle: "QP Title",
    qpTitleValue: "Physics",
  };
  const fetchData = async (endpoint, successCallback) => {
    try {
      const response = await authenticatedAxios.get(endpoint, { authenticated: true } as CustomAxiosRequestConfig);
      if (response.status === 200) {
        successCallback(response.data.responseBody);
      } else {
        const errorMsg = getIntlErrorMessage("GENERAL", "API_ERROR");
        handleMessage(messageEnum.error, errorMsg);
      }
    } catch (error) {
      const errorMsg = getIntlErrorMessage("GENERAL", "API_ERROR");
      handleMessage(messageEnum.error, errorMsg);
    }
  };
  // Explicitly defining types for state variables
  const [candidates, setCandidates] = useState<any[]>([]);
  const [violationsData, setViolationsData] = useState<any[]>([]);
  const [feedbackQuestions, setFeedbackQuestions] = useState<any[]>([]);
  const [assessmentSetCandidates, setAssessmentSetCandidates] = useState<any[]>([]);
  const [candidateAnswers, setCandidateAnswers] = useState<any[]>([]);
  const [assessmentEvaluations, setAssessmentEvaluations] = useState<any[]>([]);
  const [candidateFeedbackForm, setCandidateFeedbackForm] = useState<any[]>([]);
  const [assessmentEvidences, setAssessmentEvidences] = useState<any[]>([]);
  const assessmentId = localStorage.getItem(localStorageKeys?.assessmentId);
  useEffect(() => {
    setCandidateFeedbackForm([]);
    setViolationsData([]);
    setFeedbackQuestions([]);
    const distinctCandidates = getDistinctCandidates(candidate_details);
    setCandidates(distinctCandidates);
    fetchData( URL.ASSESSMENT.CANDIDATE_ASSESSMENT+URL.ASSESSMENT.FEEDBACK_QUESTIONS, setFeedbackQuestions);
    distinctCandidates.forEach((candidate) => {
      fetchData(
        `${URL.ASSESSMENT.CANDIDATES_ASSESSMENT}/${assessmentId}${URL.ASSESSMENT.CANDIDATE_FEEDBACK_RESPONSE}/${candidate.candidate_id}`,
        addCandidateFeedback
      );
      fetchData(`${URL.ASSESSMENT.CANDIDATES_ASSESSMENT}/${assessmentId}/candidates/${candidate.candidate_id}/evaluations`, addCaniddateEvaluation);
      fetchData(`${URL.ASSESSMENT.CANDIDATES_ASSESSMENT}/${assessmentId}/candidate/${candidate.candidate_id}/evidences`, addCandidateEvidences);
    });
    fetchData(`${URL.ASSESSMENT.CANDIDATES_ASSESSMENT}/${assessmentId}/answers`, setCandidateAnswers);
    fetchData(`${URL.ASSESSMENT.CANDIDATES_ASSESSMENT}/${assessmentId}/violations`, setViolationsData);
    fetchData(`${URL.ASSESSMENT.CANDIDATES_ASSESSMENT}/${assessmentId}/sets`, setAssessmentSetCandidates);
  }, [assessmentId]);
  const addCandidateFeedback = (feedback) => {
    if (!feedback[0]) return;
    setCandidateFeedbackForm((prevFeedback) => [...prevFeedback, feedback[0]]);
  };
  const addCaniddateEvaluation = (evaluate) => {
    setAssessmentEvaluations((prevEvaluation) => [...prevEvaluation, evaluate]);
  };
  const addCandidateEvidences = (evidence) => {
    setAssessmentEvidences((prevEvidences) => [...prevEvidences, evidence]);
  };
  const flattenObject = (obj) => {
    const result = {};
    for (const key in obj) {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        const flatObject = flattenObject(obj[key]);
        for (const flatKey in flatObject) {
          result[`${key}.${flatKey}`] = flatObject[flatKey];
        }
      } else {
        result[key] = obj[key];
      }
    }
    return result;
  };
  const downloadExcel = () => {
    const wb = XLSX.utils.book_new();
    // Helper function to ensure the input is always an array
    const ensureArray = (data) => {
      if (Array.isArray(data)) return data; // If it's already an array, return it as-is
      if (data === undefined || data === null) return []; // If null or undefined, return an empty array
      return [data]; // Wrap non-array values into an array
    };
    const addSheet = (data, sheetName) => {
      const normalizedData = ensureArray(data); // Ensure the input is always an array
      const flattenedData = normalizedData.map(flattenObject);
      let sheetData;
      if (flattenedData.length > 0) {
        // If there is data, use keys from the first object as headers and add values
        sheetData = [
          Object.keys(flattenedData[0]),
          ...flattenedData.map((item) => Object.values(item))
        ];
      } else {
        // If no data, create a placeholder sheet
        sheetData = [["No data available"]];
      }
      const ws = XLSX.utils.aoa_to_sheet(sheetData);
      XLSX.utils.book_append_sheet(wb, ws, sheetName);
    };
    // Normalize each dataset before passing it to addSheet
    addSheet(violationsData, "Violations");
    addSheet(feedbackQuestions, "Feedback Questions");
    addSheet(assessmentSetCandidates, "Assessment Set Candidates");
    addSheet(candidateAnswers, "Assessment Answers");
    addSheet(assessmentEvaluations, "Assessment Evaluations");
    addSheet(candidateFeedbackForm, "Candidate Feedback Form");
    addSheet(assessmentEvidences, "Assessment Evidences");
    addSheet(assessment_details, "Assessment Details");
    addSheet(candidates, "Candidates");
   console.log(violationsData, "Violations");
   console.log(feedbackQuestions, "Feedback Questions");
   console.log(assessmentSetCandidates, "Assessment Set Candidates");
   console.log(candidateAnswers, "Assessment Answers");
   console.log(assessmentEvaluations, "Assessment Evaluations");
   console.log(candidateFeedbackForm, "Candidate Feedback Form");
   console.log(assessmentEvidences, "Assessment Evidences");
   console.log(assessment_details, "Assessment Details");
   console.log(candidates, "Candidates");
    XLSX.writeFile(wb, "assessment_complete_details.xlsx");
  };
  return (
    <div className="bg-[#DEE1E6] px-[20px] py-[10px] flex justify-between items-center">
      <div>{`${qpDetails?.qpTitle}: ${questionPaperDetails?.[0]?.question_paper_title ?? questionPaperDetails?.question_paper_version_name}`}</div>
      <button
        onClick={downloadExcel}
        className="flex items-center bg-green-500  py-2 px-4 rounded hover:bg-green-600"
        style={{ backgroundColor: "rgba(34, 201, 92, 0.98)" }}
      >
        <span  style={{color: "white"}}className="mr-2">Download</span>
        <MdOutlineFileDownload size={"20px"} color="white"/>
      </button>
    </div>
  );
};
export default AssessmentDetailsHeader;



// type Candidate = {
//   candidate_id: number; // or string, depending on the data type of candidate_id
//   // other properties of candidate
// };

// const getDistinctCandidates = (candidates: Candidate[] = []): Candidate[] => {
//   const uniqueCandidates = new Map<number, Candidate>(); // Use number if candidate_id is a number, or string if it's a string

//   if (!Array.isArray(candidates)) {
//     console.error("Expected 'candidates' to be an array, but got:", typeof candidates);
//     return [];
//   }

//   candidates.forEach((candidate) => {
//     if (candidate && candidate.candidate_id && !uniqueCandidates.has(candidate.candidate_id)) {
//       uniqueCandidates.set(candidate.candidate_id, candidate);
//     }
//   });

//   return Array.from(uniqueCandidates.values());
// };
