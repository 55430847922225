import type React from "react"
import { useContext, useEffect } from "react"
import { ItemStateContext } from "@/contexts/questionContext"
import type { NetworkStatusProps } from "@/types/componentTypes"

const NetworkStatus: React.FC<NetworkStatusProps> = ({ onAllow }) => {
  const { setAllPermissions }: any = useContext(ItemStateContext)

  useEffect(() => {
    // Check initial connection status
    const checkConnection = () => {
      const isOnline = navigator.onLine
      setAllPermissions((prev: any) => ({
        ...prev,
        isOnline,
      }))

      // Only proceed if online
      if (isOnline) {
        calculateNetworkSpeed()
        onAllow()
      }
    }

    const handleOnline = () => {
      setAllPermissions((prev: any) => ({
        ...prev,
        isOnline: true, // Fixed: Set to true when online
      }))
      calculateNetworkSpeed()
      onAllow()
    }

    const handleOffline = () => {
      setAllPermissions((prev) => ({
        ...prev,
        isOnline: false,
        networkSpeed: null, // Clear network speed when offline
      }))
    }

    const calculateNetworkSpeed = () => {
      if ((navigator as any)?.connection?.downlink) {
        const speedMbps = (navigator as any).connection.downlink
        setAllPermissions((prev) => ({
          ...prev,
          networkSpeed: speedMbps.toFixed(2),
        }))
      } else {
        setAllPermissions((prev) => ({
          ...prev,
          networkSpeed: null,
        }))
      }
    }

    // Check initial connection status
    checkConnection()

    window.addEventListener("online", handleOnline)
    window.addEventListener("offline", handleOffline)

    return () => {
      window.removeEventListener("online", handleOnline)
      window.removeEventListener("offline", handleOffline)
    }
  }, [onAllow, setAllPermissions])

  return <div></div>
}

export default NetworkStatus

