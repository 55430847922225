import React, { useContext, useEffect, useState } from "react";
import { CheckCircleTwoTone } from "@ant-design/icons";
import ModalComponent from "@/components/customModal";
import {
  modalContent,
  placeHolders,
  messageEnum,
  analyticKeys,
  buttonTitle,
  eventType,
  firebaseMethodKeys,
  desc,
} from "@/constants/constants";
import { FIREBASE_URL, URL } from "@/utils/service-urls";
import { authenticatedAxios } from "@/utils/axiosInterceptor";
import { CustomAxiosRequestConfig } from "@/types/functionTypes";
import {
  getFirebaseUrl,
  getIntlErrorMessage,
  getIntlSuccessMessage,
  handleMessage,
} from "@/utils/functions";
import { ItemStateContext } from "@/contexts/questionContext";
import useEventLogging from "@/hooks/useLogs";
import { handleFirebaseOperation } from "@/firebase";
import { Rate, Spin } from "antd";
import { RootState } from "@/store";
import { useSelector } from "react-redux";
import { FeedbackModalComponentProps } from "@/types/componentTypes";
import { useNavigate } from "react-router-dom";

const FeedbackModalComponent: React.FC<FeedbackModalComponentProps> = ({
  feedBackModal,
  setFeedBackModal,
}) => {
  const assessmentDetails: any = JSON.parse(
    localStorage.getItem("assessment_details") ?? "{}",
  );
  const navigate = useNavigate();
  const [feedbackQuestions, setFeedbackQuestions] = useState<any>({});
  const { steps, setSteps }: any = useContext(ItemStateContext);
  const { logEvent } = useEventLogging();
  const userId = useSelector((state: RootState) => state.candidateId);
  const assessmentId = useSelector((state: RootState) => state.assessmentId);
  const setId = useSelector((state: RootState) => state.setId);
  const [values, setValues] = useState({
    ratings: [] as number[],
    feedback: "",
  });
  const [loader, setLoader] = useState<boolean>(true);
  const numberType = "number";
  useEffect(() => {
    getFeedbackQuestions();
  }, []);
  const getFeedbackQuestions = async () => {
    try {
      const response = await authenticatedAxios.get(
        URL.ASSESSMENT.CANDIDATE_ASSESSMENT + URL.ASSESSMENT.FEEDBACK_QUESTIONS,
        { authenticated: true } as CustomAxiosRequestConfig,
      );
      setFeedbackQuestions(response?.data?.responseBody);
      setValues((prevValues) => ({
        ...prevValues,
        ratings: response?.data?.responseBody,
      }));
      setLoader(false);
    } catch (error) {
      const errorMsg = getIntlErrorMessage("GENERAL", "API_ERROR");
      handleMessage(messageEnum?.error, errorMsg);
    }
  };
  const getRatings = (prevValues: any, index: any, newValue: any) => {
    return prevValues?.ratings?.map((rating: any, i: any) => {
      return i === index ? newValue : rating;
    });
  };

  const handleRateChange = (index: any) => (newValue: any) => {
    setValues((prevValues) => ({
      ...prevValues,
      ratings: getRatings(prevValues, index, newValue),
    }));
  };

  const handleTextAreaChange = (e: any) => {
    const { value } = e.target;
    setValues((prevValues) => ({
      ...prevValues,
      feedback: value,
    }));
  };

  const handleOnCancel = () => {
    setFeedBackModal(false);
    if(assessmentDetails?.rules?.showResponsesToCandidates ||  assessmentDetails?.rules?.showResultsToCandidates){
      logEvent({
        eventName: analyticKeys?.VIEW_RESPONSES,
        eventType: eventType?.POST_ASSESSMENT_ACTION,
      });
      setSteps(steps+1);
    }
    else{
      navigate(URL.ACCOUNT.LOGIN);
    }
  };

  //Validation for all the required fields of the feedback form
  const validateFeedback = () => {

    return (
      values.ratings?.every((rating) => typeof rating === numberType && rating > 0) && values.feedback.trim() !== ""
    );
  };

  const handleSubmitFeedback = async () => {
    setFeedBackModal(false);
    logEvent({
      eventName: analyticKeys?.SUBMIT_FEEDBACK,
      eventType: eventType?.POST_ASSESSMENT_ACTION,
    });
    await feedbackPostCall();
    if(assessmentDetails?.rules?.showResponsesToCandidates ||  assessmentDetails?.rules?.showResultsToCandidates){
      setSteps(steps+1);
    }
    else{
      navigate(URL.ACCOUNT.LOGIN);
    }
  };
  const feedbackPostCall = async () => {
    if (!validateFeedback()) {
      return;
    }

    const checkingNumberRating = values?.ratings?.map((rating) => {
      return typeof rating === numberType ? rating : 0;
    });

    const formattedRatings = checkingNumberRating?.reduce(
      (acc, rating, index) => {
        acc[index + 1] = rating;
        return acc;
      },
      {},
    );
    const feedbackData = {
      ...formattedRatings,
      feedback: values?.feedback,
    };
    try {
      const options = {
        assessmentId: assessmentId,
        setId: setId,
        candidateId: userId,
        endpoint: FIREBASE_URL?.ENDPOINT?.FEEDBACK,
      };

      const firebaseUrl = getFirebaseUrl(options);
      await handleFirebaseOperation(
        firebaseUrl,
        firebaseMethodKeys.PUT,
        feedbackData,
      );
      const successMsg = getIntlSuccessMessage(
          "FEEDBACK_SUBMITTED_MESSAGE",
          "SUBMIT_SUCCESS",
        );
      handleMessage(messageEnum.success, successMsg);
    } catch (error) {
      const errorMsg = getIntlErrorMessage(
        "FEEDBACK_MESSAGES",
        "FEEDBACK_SUBMIT_ERROR",
      );
      handleMessage(messageEnum.error, errorMsg);
    }
  };
  const renderFeedbackQuestions = () => {
    return feedbackQuestions?.map((question: any, index: any) => (
      <div
        key={question.question}
        className="flex items-center justify-evenly space-y-[20px]">
        <p className="w-[60%] pr-[50px] pt-[20px] text-[14px]">
          {question.question}
        </p>
        <Rate
          tooltips={desc}
          onChange={handleRateChange(index)}
          value={values.ratings[index]}
          className="text-[20px] flex space-x-[10px]"
          defaultValue={3}
        />
      </div>
    ));
  };
  return (
    <div>
      {feedBackModal && (
        <ModalComponent
          title={
            <div className="flex items-center justify-center mt-[30px] font-bold text-base pb-[10px] border-b-[1px] border-gray">
              {modalContent.rateAssessemt}
            </div>
          }
          // action={
          //   <div className="flex items-center justify-center gap-2 text-lg pt-[10px]">
          //     <CheckCircleTwoTone twoToneColor="#52c41a" />
          //     <div>{modalContent.submitFeedbackSuccess}</div>
          //   </div>
          // }
          content={
            <div>
              {!loader ? (
                <>
                  {renderFeedbackQuestions()}
                  <textarea
                    rows={5}
                    className="w-[89%] border-[1px] text-[14px] border-solid border-gray text-primaryText p-[10px] mt-[20px] mx-[34px] rounded-md mb-[20px]"
                    placeholder={placeHolders.yourFeedback}
                    style={{ resize: "none" }}
                    onChange={handleTextAreaChange}></textarea>
                </>
              ) : (
                <div className="flex justify-center py-[20px]">
                  <Spin size={"large"} />
                </div>
              )}
            </div>
          }
          width={700}
          isModalOpen={feedBackModal}
          buttonName={buttonTitle.submitFeedback}
          modalButtonClick={handleSubmitFeedback}
          closeIcon={true}
          disabled={!validateFeedback()}
          buttonClass={"flex items-center justify-end text-[white]"}
          handleOnCancel={handleOnCancel}
        />
      )}
    </div>
  );
};

export default FeedbackModalComponent;
