import React, { useContext, useEffect, useState } from "react";
import { ZegoExpressEngine } from "zego-express-engine-webrtc";
import { HiOutlineVideoCamera } from "react-icons/hi2";
import { HiOutlineVideoCameraSlash } from "react-icons/hi2";
import { AiOutlineAudio } from "react-icons/ai";
import { AiOutlineAudioMuted } from "react-icons/ai";
import { MdOutlineCallEnd } from "react-icons/md";
import {
  stepsIconStyles,
  messageEnum,
  vivaTooltips,
  vivaData,
  localStorageKeys,
  stakeholderLogValues,
} from "@/constants/constants";
import {
  handleMessage,
  getIntlSuccessMessage,
  getIntlErrorMessage,
} from "@/utils/functions";
import { ItemStateContext } from "@/contexts/questionContext";
import { Tooltip } from "antd";
import classNames from "classnames";
import { authenticatedAxios } from "@/utils/axiosInterceptor";
import { CustomAxiosRequestConfig } from "@/types/functionTypes";
import { URL } from "@/utils/service-urls";
import { useSocket } from "@/contexts/socketContext";
import useStakeholderEventLogging from "@/hooks/useStakeholderLogs";
const VideoConferencing = ({
  setVivaStarted,
  isAssessmentDetailsPage = false,
  isMonitorTab = false,
}) => {
  const [localStream, setLocalStream] = useState<any | null>(null);
  const [remoteStreams, setRemoteStreams] = useState<any[]>([]);
  const [zegoInstance, setZegoInstance] = useState<any>(null);
  const [loginSuccess, setLoginSuccess] = useState(false);
  const [streamId, setStreamId] = useState("");
  const { socket }: any = useSocket();
  const candidateId = localStorage.getItem(localStorageKeys.candidateId);
  const assessmentId = localStorage.getItem(localStorageKeys.assessmentId);
  const setId = localStorage.getItem(localStorageKeys.setId);
  const stakeHolderDetails = JSON.parse(
    localStorage.getItem("stakeholder_details") ?? "{}",
  );
  const { stakeholderLogEvent } = useStakeholderEventLogging();
  const {
    setVivaResponse,
    currentQuestionIndex,
    setVivaQuestion,
    selectedName,
    setIsConnected,
    setIsChatRoomOpen,
  }: any = useContext(ItemStateContext);
  const [permissions, setPermissions] = useState({
    videoStopped: false,
    audioStopped: false,
    shareStopped: false,
  });
  const vivaCredentials = {
    roomId: "153",
    userName: "",
  };
  const updateTypes = {
    delete: "DELETE",
    add: "ADD",
  };
  const zegoMethods = {
    roomStreamUpdate: "roomStreamUpdate",
    screenEnd: "screenSharingEnded",
  };
  const generateToken = async () => {
    try {
      const response = await authenticatedAxios.get(
        URL.VIVA.ZEGO_CLOUD_TOKEN + `/${assessmentId}` + URL.VIVA.TOKEN,
        {
          authenticated: true,
        } as CustomAxiosRequestConfig,
      );
      return response;
    } catch (error) {
      const errorMsg = getIntlErrorMessage("GENERAL", "API_ERROR");
      handleMessage(messageEnum?.error, errorMsg);
    }
  };
  useEffect(() => {
    const initializeApp = async () => {
      const response = await generateToken();
      const zegoAppId = process.env.REACT_APP_ZEGO_APP_ID;
      const zegoServerId = process.env.REACT_APP_ZEGO_SERVER_ID;
      const zegoToken = response?.data?.responseBody?.data?.token;
      if (
        typeof zegoAppId !== "string" ||
        typeof zegoServerId !== "string" ||
        typeof zegoToken !== "string"
      ) {
        console.error(
          "Zego environment variables are not defined or not of type string.",
        );
        return;
      }
      const zg = new ZegoExpressEngine(parseInt(zegoAppId), zegoServerId);
      await renderVideoStreams(zg);
      await joinRoom(
        zg,
        zegoToken,
        response?.data?.responseBody?.data?.user_id,
      );
      setVivaResponse((prevResponse) => {
        const updatedResponse = { ...prevResponse };
        if (updatedResponse[currentQuestionIndex]) {
          updatedResponse[currentQuestionIndex].visited = true;
        }
        return updatedResponse;
      });
    };

    initializeApp();
  }, []);
  const joinRoom = async (zg, zegoToken, user_id) => {
    try {
      await zg.loginRoom(
        vivaCredentials?.roomId,
        zegoToken,
        {
          userID: user_id,
          userName: vivaCredentials?.userName,
        },
        { userUpdate: true },
      );
      setZegoInstance(zg);
      setLoginSuccess(true);
      // eslint-disable-next-line no-extra-boolean-cast
      if (!!stakeHolderDetails?.is_stakeholder) {
        await localStreamSetup(zg);
        socket.emit("viva-ongoing", selectedName.id);
      }
      socket.emit("joined-viva", candidateId);
    } catch (error) {
      console.error("Error during login:", error);
      setLoginSuccess(false);
    }
  };
  const renderVideoStreams = (zg) => {
    zg.on(
      zegoMethods?.roomStreamUpdate,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      async (roomID, updateType, streamList, extendedData) => {
        if (updateType === updateTypes?.add) {
          streamList.forEach(async (stream) => {
            try {
              const playingStream = await zg.startPlayingStream(
                stream.streamID,
                {
                  audio: true,
                  video: true,
                },
              );
              setRemoteStreams((prevStreams) => [
                ...prevStreams,
                playingStream,
              ]);
              if (!isMonitorTab) {
                await localStreamSetup(zg);
              }
            } catch (error) {
              console.error(
                "Error occurred while starting to play stream:",
                error,
              );
            }
          });
        } else if (updateType === updateTypes?.delete) {
          zg.stopPublishingStream(streamList[0].streamID);
          zg.logoutRoom(vivaCredentials?.roomId);
        }
      },
    );
  };
  const localStreamSetup = async (zg) => {
    try {
      const stream = await zg.createZegoStream({
        camera: { audio: true, video: true },
      });
      setLocalStream(stream);
      const streamID = vivaCredentials?.roomId + Date.now();
      setStreamId(streamID);
      zg.startPublishingStream(streamID, stream);
      stakeholderLogEvent({
        candidateId: candidateId,
        setId: setId,
        eventType: stakeholderLogValues?.CONDUCT_VIVA,
        eventName: stakeholderLogValues?.VIVA_STARTED,
      });
    } catch (error) {
      console.error("Error occurred while creating local stream:", error);
    }
  };

  const handleStopRecording = () => {
    if (localStream && zegoInstance && streamId) {
      zegoInstance.stopPublishingStream(streamId);
      zegoInstance.destroyStream(localStream);
      remoteStreams.forEach((stream) => {
        zegoInstance.stopPlayingStream(stream.id);
      });
      zegoInstance.logoutRoom(vivaCredentials?.roomId);
      setRemoteStreams([]);
      const successMsg = getIntlSuccessMessage(
        "VIDEOCALL_END_MESSAGE",
        "VIVA_COMPLETE",
      );
      handleMessage(messageEnum.success, successMsg);
      setVivaStarted(false);
      setVivaQuestion(false);
      setIsConnected(false);
      setIsChatRoomOpen(false);
      if (stakeHolderDetails?.is_stakeholder) {
        socket.emit("complete-viva", selectedName.id);
        stakeholderLogEvent({
          candidateId: candidateId,
          setId: setId,
          eventType: stakeholderLogValues?.CONDUCT_VIVA,
          eventName: stakeholderLogValues?.VIVA_COMPLETED,
        });
      }
    }
  };
  const handleStopVideo = async () => {
    if (zegoInstance) {
      try {
        if (!permissions.videoStopped) {
          setPermissions((prev) => ({ ...prev, videoStopped: true }));
          await zegoInstance.mutePublishStreamVideo(localStream, true);
        } else {
          setPermissions((prev) => ({ ...prev, videoStopped: false }));
          await zegoInstance.mutePublishStreamVideo(localStream, false);
        }
      } catch (error) {
        console.error("Error occurred while toggling video:", error);
      }
    }
  };

  const handleStopAudio = async () => {
    if (zegoInstance) {
      try {
        if (permissions && permissions.audioStopped) {
          await zegoInstance.mutePublishStreamAudio(localStream, false);
          setPermissions((prev) => ({ ...prev, audioStopped: false }));
        } else {
          setPermissions((prev) => ({ ...prev, audioStopped: true }));
          await zegoInstance.mutePublishStreamAudio(localStream, true);
        }
      } catch (error) {
        console.error("Error occurred while toggling audio:", error);
      }
    }
  };

  const StreamSrcObject = (stream: any, videoElement: any) => {
    if (videoElement && stream instanceof MediaStream) {
      try {
        videoElement.srcObject = stream;
      } catch (error) {
        console.error("Error setting stream:", error);
      }
    }
  };
  const vivaIcons = {
    divStyles: {
      padding: isAssessmentDetailsPage ? "7px" : "10px",
      borderRadius: "50%",
      backgroundColor: stepsIconStyles?.stepsIconColor,
      margin: "10px",
    },
    iconStyles: {
      color: "white",
      fontWeight: stepsIconStyles?.stepsIconFontWeight,
    },
  };

  const buttons = [
    {
      onClick: handleStopVideo,
      icon: permissions?.videoStopped ? (
        <HiOutlineVideoCameraSlash
          size={isAssessmentDetailsPage ? 25 : 30}
          style={{ ...vivaIcons.iconStyles }}
        />
      ) : (
        <HiOutlineVideoCamera
          size={isAssessmentDetailsPage ? 25 : 30}
          style={{ ...vivaIcons.iconStyles }}
        />
      ),
      tooltip: permissions?.videoStopped
        ? vivaTooltips?.cameraOn
        : vivaTooltips?.cameraOn,
    },
    {
      onClick: handleStopAudio,
      icon: permissions?.audioStopped ? (
        <AiOutlineAudioMuted
          size={isAssessmentDetailsPage ? 25 : 30}
          style={{ ...vivaIcons.iconStyles }}
        />
      ) : (
        <AiOutlineAudio
          size={isAssessmentDetailsPage ? 25 : 30}
          style={{ ...vivaIcons.iconStyles }}
        />
      ),
      tooltip: permissions?.audioStopped
        ? vivaTooltips?.mute
        : vivaTooltips?.unMute,
    },
    {
      onClick: handleStopRecording,
      icon: (
        <MdOutlineCallEnd
          size={isAssessmentDetailsPage ? 25 : 30}
          style={{ ...vivaIcons.iconStyles }}
        />
      ),
      tooltip: vivaTooltips?.leave,
    },
  ];
  console.log(
    loginSuccess,
    "theory--login",
    isAssessmentDetailsPage,
    isMonitorTab,
    isAssessmentDetailsPage && isMonitorTab,
    false && false,
  );
  return (
    <div>
      {loginSuccess && (
        <div>
          {localStream || isMonitorTab ? (
            <div>
              <div
                className={classNames(
                  "border-[2px] border-[lightgray] border-solid grid grid-cols-2 w-[100%]",
                  !isAssessmentDetailsPage &&
                    !isMonitorTab &&
                    "mx-[100px] mt-[30px]",
                  remoteStreams?.length > 1 &&
                    !isAssessmentDetailsPage &&
                    "overflow-y-auto h-[200px] ",
                  remoteStreams?.length > 1 &&
                    isAssessmentDetailsPage &&
                    "h-[200px] overflow-y-auto",
                  !isMonitorTab && "w-[100%]",
                )}>
                {localStream && (
                  <video
                    id="local-video"
                    className={classNames(
                      " w-[100%] bg-[white] border-r-[2px] border-[lightgray] border-solid",
                      isAssessmentDetailsPage
                        ? "py-[10px] h-[200px]"
                        : "h-[200px]",
                    )}
                    autoPlay={true}
                    muted={true}
                    playsInline={true}
                    ref={(video) => StreamSrcObject(localStream.stream, video)}
                  />
                )}

                <div id="remote-video">
                  {remoteStreams.map((stream, index) => (
                    <video
                      key={index}
                      className={classNames(
                        "w-[100%] bg-[white] ",
                        isAssessmentDetailsPage
                          ? "py-[10px] h-[200px]"
                          : "h-[200px]",
                      )}
                      autoPlay
                      playsInline
                      ref={(video) => {
                        if (video && stream instanceof MediaStream) {
                          try {
                            video.srcObject = stream;
                          } catch (error) {
                            console.error("Error setting remoteStream:", error);
                          }
                        }
                      }}
                    />
                  ))}
                </div>
              </div>
              {!isMonitorTab && (
                <div
                  className={classNames(
                    "flex justify-center items-center  space-x-[10px]",
                    !isAssessmentDetailsPage
                      ? "pl-[200px] pt-[20px]"
                      : "pt-[5px]",
                  )}>
                  {buttons.map((button, index) => (
                    <Tooltip
                      title={button.tooltip}
                      key={index}
                      placement="bottom">
                      <div
                        onClick={button.onClick}
                        className="cursor-pointer"
                        style={{ ...vivaIcons.divStyles }}>
                        {button.icon}
                      </div>
                    </Tooltip>
                  ))}
                </div>
              )}
            </div>
          ) : (
            !isAssessmentDetailsPage &&
            !isMonitorTab && (
              <div
                className={classNames(
                  " border-[2px] border-[lightgray] border-solid rounded-[2px] text-[20px] flex items-center justify-center bg-lightGray h-[200px] w-[100%] mx-[100px] mt-[30px]",
                )}>
                {vivaData?.waitingForExaminer}
              </div>
            )
          )}
        </div>
      )}
    </div>
  );
};
export default VideoConferencing;
