import React, { useEffect, useState } from 'react';
import { CustomAxiosRequestConfig } from "@/types/functionTypes";
import { authenticatedAxios } from '@/utils/axiosInterceptor';
export default function Integrity({ assessmentId, candidateId }) {
  const [integrityScore, setIntegrityScore] = useState<number>(0);
  const [violations, setViolations] = useState<any[]>([]);
  const [images, setImages] = useState<any[]>([]);
  useEffect(() => {
    const url = `/candidates/assessments/${assessmentId}/candidate/${candidateId}/Integrity_score_and_violation_evidence`;
    // Fetch data from the server
    const fetchData = async () => {
      try {
        const response = await authenticatedAxios.get(url, { authenticated: true } as CustomAxiosRequestConfig);
        if (response && response.data) {
          // Assuming the response contains two arrays: violations and images
          console.log(response, "response");
          // Extract the violations and images from the response body
          const violationsArray = response.data.responseBody[0] || [];
          const imagesArray = response.data.responseBody[1] || [];
          // Remove duplicates by ID from both arrays
          const uniqueViolations = Array.from(new Map(violationsArray.map(item => [item.id, item])).values());
          const uniqueImages = Array.from(new Map(imagesArray.map(item => [item.id, item])).values());
          setViolations(uniqueViolations);
          setImages(uniqueImages);
          console.log(uniqueViolations); // Log unique violations
          console.log(uniqueImages); // Log unique images
          // Calculate integrity score
          const imageCount = uniqueImages.filter((image: any) => image.type === "image").length;
          console.log(imageCount);
          // Calculate the integrity score based on the formula provided
          if (imageCount > 0) {
            const score = (1 - (uniqueViolations.length / imageCount)) * 100;
            console.log(score);
            setIntegrityScore(score);
          } else {
            setIntegrityScore(100); // If no "image" types, set score to 0
          }
        }
      } catch (error) {
        console.error("Error fetching integrity data:", error);
      }
    };
    fetchData();
  }, [assessmentId, candidateId]);
  const violationMessages={
    mobile : "Mobile Phone Detected",
    face : "Multiple Face Detected",
  }
  return (
    <div className="overflow-auto flex flex-col h-[55vh] flex-1 pr-[10px]">
      <div className="flex justify-between items-center">
        <div className="font-medium">Monitoring System</div>
        <div className="flex flex-col bg-[#DEE1E6] space-x-[10px] px-[50px] py-[10px] rounded-[5px] items-center justify-center">
          <div>Integrity Score</div>
          <div className="font-medium">{integrityScore.toFixed(2)}%</div>
        </div>
      </div>
      <div className="flex flex-col space-y-[10px] rounded mt-[5px] overflow-auto mb-[20px] pb-[20px]">
        <div>
          {violations && violations.length===0 ? 
          <div className="w-[50%] p-[10px] text-wrap bg-[#bcf2cf] m-[10px]">No suspicious activities in face detection</div> 
          : 
          violations.map((violation, index) => (
            <div key={index} className="w-[70%] p-[10px] text-wrap bg-[#FAE7C0] m-[10px]">
           {JSON.parse(violation.description)?.violations?.["Mobile Phone"]
              ? violationMessages.mobile
              : violation.description || "No suspicious activities in the face detection"}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}