import React, { useContext, useRef } from "react";
import { Input } from "antd";
import { ItemStateContext } from "@/contexts/questionContext";
import useEventLogging from "@/hooks/useLogs";
import {
  analyticKeys,
  eventType,
  questionType,
  responseText,
  delayTiming,
} from "@/constants/constants";
import { debounce, getBlankIndices } from "@/utils/functions";
import { FillInTheBlanksQuestionProps } from "@/types/componentTypes";

const FillInTheBlanksQuestion: React.FC<FillInTheBlanksQuestionProps> = ({
  question,
  fillInTheBlanksResponse,
  selectedLanguage,
  index,
  ourResponse,
  isResponsivePage = false,
  optionsData,
}) => {
  const { options } = question;

  const { actual_statement_text } = options?.[0] || {};
  const blank_indices_start_end_data = getBlankIndices(actual_statement_text);
  const { logEvent } = useEventLogging();
  const logEventDebounced = useRef(
    debounce(logEvent, delayTiming?.debounceTimeDelay),
  ).current;

  const processText = () => {
    const textParts: (
      | { type: "text"; text: string }
      | { type: "input"; name: string; value: string }
    )[] = [];
    const blanks = Object.values(blank_indices_start_end_data || {}) as {
      start_index: number;
      end_index: number;
      blank_text: string;
      bit_index: string;
    }[];

    blanks.sort((a, b) => a.start_index - b.start_index);

    let lastIndex = 0;
    for (const { start_index, end_index, bit_index } of blanks) {
      const preBlankText =
        actual_statement_text?.slice(lastIndex, start_index) || "";
      if (preBlankText) {
        textParts.push({
          type: "text",
          text: preBlankText,
        });
      }

      const inputName = bit_index;
      const inputValue = fillInTheBlanksResponse?.[inputName] || "";
      textParts.push({
        type: "input",
        name: inputName,
        value: inputValue,
      });
      lastIndex = end_index + 1;
    }
    const remainingText = actual_statement_text?.slice(lastIndex) || "";
    if (remainingText) {
      textParts.push({
        type: "text",
        text: remainingText,
      });
    }
    return textParts;
  };

  const {
    currentQuestionIndex,
    setResponses,
    setCurrentQuestionIndex,
    fromSampleAssessment,
    sectionNumber,
  }: any = useContext(ItemStateContext);
  const handleInputBoxChange = (
    inputName: string,
    value: string,
    questionId: number,
  ) => {
    if (index !== undefined) {
      setCurrentQuestionIndex(index);
    }
    setResponses((prevResponses: any) => {
      const updatedResponses = { ...prevResponses };
      const questionResponses =
        updatedResponses?.[selectedLanguage]?.[index || currentQuestionIndex] ||
        {};

      questionResponses.fillInTheBlanksResponse = {
        ...(questionResponses.fillInTheBlanksResponse || {}),
        [inputName]: value,
      };
      questionResponses.questionId = questionId;

      // Set reviewed to false
      questionResponses.reviewed = false;

      updatedResponses[selectedLanguage] = {
        ...(updatedResponses[selectedLanguage] || {}),
        [index || currentQuestionIndex]: questionResponses,
      };

      return updatedResponses;
    });
  };

  const textParts = processText();
  const compareResponses = (
    fillInTheBlanksResponse: any,
    inputResponse: any,
  ): boolean => {
    return (
      inputResponse &&
      fillInTheBlanksResponse &&
      Object?.values(fillInTheBlanksResponse)?.every(
        (item, index) => inputResponse?.[index + 1] === item,
      )
    );
  };

  const isResponseCorrect = compareResponses(
    fillInTheBlanksResponse,
    optionsData?.[0]?.blank_text_ans_key_data,
  );
  return (
    <div className="fill-in-the-blanks-question">
      <div className="flex items-center gap-2 flex-wrap px-[32px] pb-[10px] pt-[5px]">
        {textParts?.map((part, index) =>
          part.type === "text" ? (
            <span
              key={`${part.text}-${index}`}
              dangerouslySetInnerHTML={{ __html: part.text ?? "" }}
            />
          ) : (
            <Input.TextArea
              autoFocus
              style={{
                width: `${Math.max(200, part.value?.length * 10)}px`,
                maxWidth: "600px",
              }}
              key={`textarea-${part.type}-${index}`}
              value={ourResponse ? "" : part.value ?? ""}
              onChange={(e) => {
                if (!ourResponse) {
                  const newValue = e.target.value;
                  const inputName = part?.name;
                  if (inputName) {
                    handleInputBoxChange(inputName, newValue, question?.id);
                  }
                  if (!fromSampleAssessment) {
                    logEventDebounced({
                      section: sectionNumber,
                      questionIndex: currentQuestionIndex,
                      eventName: analyticKeys?.ANSWER_SELECTION,
                      eventType: eventType.ASSESSMENT_ACTION,
                      questionType: questionType.filltype,
                      answer: newValue,
                    });
                  }
                }
              }}
              readOnly={ourResponse}
              autoSize={{ minRows: 1, maxRows: 5 }}
              onInput={(e) => {
                const input = e.target as HTMLTextAreaElement;
                const scrollWidth = input.scrollWidth;
                const characterWidth = 7;
                const maxAllowedWidth = window.innerWidth - 150;
                if (scrollWidth > maxAllowedWidth) {
                  input.style.width = "100%";
                  input.style.whiteSpace = "wrap";
                } else if (input.value.length * characterWidth > 100) {
                  const newWidth = input.value.length * characterWidth;
                  input.style.width = `${newWidth}px`;
                }
              }}
            />
          ),
        )}
      </div>
      {isResponsivePage &&
        ourResponse &&
        Object?.values(fillInTheBlanksResponse)?.some(
          (item: any) => item.length > 0,
        ) &&
        textParts.some((text) => text.type === "input") && (
          <div
            className={`flex ${
              !textParts.some((text) => text.type === "input")
                ? "gap-5"
                : " gap-1"
            } mx-[32px] my-[5px] border-[gray] relative bg-[#f1f1f1] p-[10px] mt-[10px] border-[2px] rounded-md`}>
            {textParts.map((text) => {
              if (text.type === "input") {
                return (
                  <div key={text.value}>
                    {text.value ? (
                      <div className="flex gap-[1px]">
                        <div>{text.value}</div>
                      </div>
                    ) : null}
                  </div>
                );
              }
              return null;
            })}
          </div>
        )}

      {isResponsivePage && !ourResponse && (
        <div>
          <div>
            {!isResponseCorrect && (
              <div>
                {textParts.some((text) => text.type === "input") ? (
                  <div
                    className={`flex ${
                      !textParts.some((text) => text.type === "input")
                        ? "gap-5"
                        : "gap-1"
                    } mx-[32px] my-[5px] relative p-[10px] mt-[10px] rounded-md ${
                      isResponseCorrect
                        ? "border-[#7ed07e] bg-[#deffde]"
                        : "border-[red] bg-lightRed"
                    } border-2`}>
                    {textParts.map((text) => {
                      if (text.type === "input") {
                        return (
                          <div key={text.value}>
                            {text.value ? (
                              <div className="flex gap-[1px]">
                                <div>{text.value}</div>
                              </div>
                            ) : null}
                          </div>
                        );
                      }
                      return null;
                    })}
                    <div className="absolute text-[12px] text-[white] rounded-tl-sm rounded-br-sm bg-[red] right-0 bottom-[0px] px-[8px] py-[1px] ">
                      {responseText?.yourAnswer}
                    </div>
                  </div>
                ) : null}
              </div>
            )}
          </div>
          {optionsData?.[0]?.blank_indices_start_end_data && (
            <div className="flex gap-5 mx-[32px] my-[5px] border-[#7ed07e] relative bg-[#deffde] p-[10px] mt-[10px] border-[2px] rounded-md">
              {optionsData?.[0]?.blank_indices_start_end_data?.map(
                (answer: any) => {
                  return (
                    <div className="flex gap-[1px]" key={answer.ans}>
                      <div>{answer.ans}</div>
                    </div>
                  );
                },
              )}

              <div className="absolute text-[12px] text-[white] rounded-tl-sm rounded-br-sm bg-primary right-0 bottom-[0px] px-[8px] py-[1px] ">
                {responseText?.correctAnswer}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default FillInTheBlanksQuestion;
