import React, { useContext, useEffect, useState } from "react";
import StepsComponent from "@/components/steps";
import Header from "@/components/header";
import useSystemCheck from "@/hooks/useSystemCheck";
import ModalComponent from "@/components/customModal";
import {
  buttonTitle,
  contentDivText,
  localStorageKeys,
  analyticKeys,
  eventType,
} from "@/constants/constants";
import { ItemStateContext } from "@/contexts/questionContext";
import useEventLogging from "@/hooks/useLogs";
import { ItemStateContextValues } from "@/types/contextTypes";

const SystemRequirementCheck = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const isSharingRequired = true;
  const isVPNRequired = true;

  const { logEvent } = useEventLogging();
  const {
    isModalOpen,
    setIsModalOpen,
    allPermissions,
    setSteps,
    steps,
    currentLocation,
    setCurrentLocation,
  }: ItemStateContextValues = (useContext(ItemStateContext) ||
    {}) as ItemStateContextValues;
  const systemCheckDone = useSystemCheck(allPermissions);
  const handleStepChange = (curr: any, totalLength: any) => {
    if (curr < totalLength - 1) {
      setTimeout(() => setCurrentStep(currentStep + 1), 2000);
    } else {
      return;
    }
  };
  useEffect(() => {
    const cameFromInstructionScreen = localStorage.getItem(
      localStorageKeys.isIntsructionScreenVisited,
    );
    if (!cameFromInstructionScreen) {
      localStorage.removeItem(localStorageKeys.isIntsructionScreenVisited);
    }
  });

  useEffect(() => {
    logEvent({
      eventName: analyticKeys?.SYSTEM_REQUIREMENTS_CHECK_START,
      eventType: eventType?.PRE_ASSESSMENT_ACTION,
    });
  }, []);

  useEffect(() => {
    setIsModalOpen(true);
    if (isModalOpen) {
      logEvent({
        eventName: analyticKeys?.SYSTEM_REQUIREMENTS_CHECK_COMPLETE,
        eventType: eventType?.PRE_ASSESSMENT_ACTION,
      });
    }
  }, [systemCheckDone]);
  return (
    <div>
      <Header heroHeader={true} />
      <div className="pt-[50px] pl-[100px]">
        <div className="text-[21px] text-primaryText font-bold pb-[50px]">
          {contentDivText.checkRequirements}
        </div>
        <StepsComponent
          currentStep={currentStep}
          currentLocation={currentLocation}
          setCurrentLocation={setCurrentLocation}
          handleStepChange={handleStepChange}
          isSharingRequired={isSharingRequired}
          isVPNRequired={isVPNRequired}
        />
      </div>
      {!!systemCheckDone && (
        <div>
          {isModalOpen && (
            <ModalComponent
              title={buttonTitle.allSetNowReadyToBegin}
              titleClass={
                "text-primaryText text-[20px] font-semibold pb-[20px] text-center"
              }
              width={340}
              buttonName={buttonTitle.readyStartNow}
              buttonClass={"flex justify-center items-center"}
              modalButtonClick={() => {
                setSteps(steps + 1);
                setIsModalOpen(false);
                logEvent({
                  eventName: analyticKeys?.ASSESSMENT_START,
                  eventType: eventType?.PRE_ASSESSMENT_ACTION,
                });
              }}
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default SystemRequirementCheck;
