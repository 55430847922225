import React, { useContext, useEffect, useState } from "react";
import { ItemStateContext } from "@/contexts/questionContext";
import { Collapse } from "antd";
import {
  subjectOptions,
  CollapseItems,
  analyticKeys,
  eventType,
  LanguageType,
  localStorageKeys,
} from "@/constants/constants";
import useEventLogging from "@/hooks/useLogs";
import { RightComponentCollapseProps } from "@/types/componentTypes";
import QuestionItem from "./questionStatusColors";

const RightComponentCollapse: React.FC<RightComponentCollapseProps> = ({
  questionsResponse,
}) => {
  const activityTypeQuestions: any = JSON.parse(
    localStorage.getItem("question_paper_practicals") ?? "{}",
  );
  const vivaQuestions: any = JSON.parse(
    localStorage.getItem("question_paper_viva") ?? "{}",
  );
  const { logEvent } = useEventLogging();
  const hasTheory = JSON.parse(
    localStorage.getItem("has_theory") ?? "{}",
  );
  const hasPracticals = JSON.parse(
    localStorage.getItem("has_practicals") ?? "{}",
  );
  const hasViva = JSON.parse(localStorage.getItem("has_viva") ?? "{}");
  const {
    setCurrentQuestionIndex,
    setSelectedLanguage,
    responses,
    fromSampleAssessment,
    sectionNumber,
    setSectionNumber,
    currentQuestionIndex,
    singleQuestionType,
    selectedPart,
    setSelectedPart,
    selectedLanguage,
    inVivaQuestion,
  }: any = useContext(ItemStateContext);
  const [activeKey, setActiveKey] = useState([CollapseItems.PART_1_KEY]);

  useEffect(() => {
    setActiveKey([
      selectedPart === CollapseItems.PART_1_LABEL
        ? CollapseItems.PART_1_KEY
        : selectedPart === CollapseItems.PART_2_LABEL
          ? CollapseItems.PART_2_KEY
          : CollapseItems.PART_3_KEY,
    ]);
  }, [selectedPart]);

  const isFillInTheBlanksEmpty = (response: any) => {
    if (response?.fillInTheBlanksResponse) {
      const fillInTheBlanksResponse =
        response.fillInTheBlanksResponse as Record<string, string>;
      return Object.values(fillInTheBlanksResponse).every(
        (value: string) => value === "",
      );
    }
    return true;
  };

  const isObjectEmpty = (obj: any) => {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        return false;
      }
    }
    return true;
  };

  const isMatchingEmpty = (response: any) => {
    if (response?.matchingResponse) {
      return isObjectEmpty(response.matchingResponse);
    }
    return true;
  };

  const isScenarioBasedQuestionEmpty = (response: any) => {
    if (response) {
      const isCheckboxEmpty =
        !response.scenarioResponse?.checkboxResponse ||
        response.scenarioResponse.checkboxResponse.length === 0;

      const isRadioEmpty =
        !response.scenarioResponse?.radioResponse ||
        response.scenarioResponse.radioResponse.trim() === "";

      return isCheckboxEmpty && isRadioEmpty;
    }
    return true;
  };

  const showQuestion =
    (index: number, language: LanguageType, label: string) => () => {
      if (!inVivaQuestion) {
        const selectedOption = subjectOptions.find(
          (option) => option.value === language,
        );
        setSectionNumber(selectedOption?.id);
        const assessmentDetailsString = localStorage.getItem(
          localStorageKeys?.assessmentDetails,
        );
        let assessmentDetailsData;
        if (assessmentDetailsString !== null) {
          assessmentDetailsData = JSON.parse(assessmentDetailsString);
        }
        const assessmentDetails = assessmentDetailsData?.rules;
        if (assessmentDetails?.jumpQuestions && language === selectedLanguage) {
          setCurrentQuestionIndex(index);
        }
        if (assessmentDetails?.jumpSections && language !== selectedLanguage) {
          setSelectedLanguage(language);
          setCurrentQuestionIndex(index);
        }
        setSelectedPart(label);
        if (!fromSampleAssessment) {
          logEvent({
            section: sectionNumber,
            questionIndex: currentQuestionIndex,
            eventName: analyticKeys.VIEW_QUESTION,
            eventType: eventType.ASSESSMENT_ACTION,
            questionType: singleQuestionType,
          });
        }
      }
    };

  const onChange = (key: any) => {
    setActiveKey(key);
  };

  //Handled exeptions of missing theory category at initial
  interface Item {
    key: any;
    header: any;
    disabled: boolean;
    collapsible: boolean;
    children: JSX.Element;
  }
  
  const items: Item[] = [];

  const isPart2Collapsible = selectedPart !== CollapseItems.PART_2_LABEL;
  if (!fromSampleAssessment) {
    if(hasTheory) {
      items.push({
        key: CollapseItems.PART_1_KEY,
        header: CollapseItems.PART_1_LABEL,
        disabled: selectedPart !== CollapseItems.PART_1_LABEL,
        collapsible: selectedPart !== CollapseItems.PART_1_LABEL,
        children: (
          <div>
            {questionsResponse &&
              Object.keys(questionsResponse)?.map((language, sectionIndex) => (
                <div key={`${language}-${sectionIndex}`} className="pb-[20px]">
                  <div className="flex flex-col justify-center items-center">
                    <div className="pb-[20px]">section {sectionIndex + 1}</div>
                    <div className="grid grid-cols-3 gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 sm:grid-cols-1">
                      {questionsResponse?.[language]?.map((question, index) => (
                        <QuestionItem
                          key={question.id}
                          question={question}
                          index={index}
                          language={language}
                          responses={responses}
                          showQuestion={showQuestion(
                            index,
                            language,
                            CollapseItems.PART_1_LABEL,
                          )}
                          isFillInTheBlanksEmpty={isFillInTheBlanksEmpty}
                          isMatchingEmpty={isMatchingEmpty}
                          isScenarioBasedQuestionEmpty={
                            isScenarioBasedQuestionEmpty
                          }
                        />
                      ))}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        ),
      },)
    }
    if (hasPracticals) {
      items.push({
        key: CollapseItems.PART_2_KEY,
        header: CollapseItems.PART_2_LABEL,
        disabled: isPart2Collapsible,
        collapsible: isPart2Collapsible,
        children: (
          <div>
            {activityTypeQuestions &&
              Object.keys(activityTypeQuestions)?.map(
                (language, sectionIndex) => (
                  <div
                    key={`${language}-${sectionIndex}`}
                    className="pb-[20px]">
                    <div className="flex flex-col justify-center items-center">
                      <div className="pb-[20px]">
                        section {sectionIndex + 1}
                      </div>
                      <div className="grid grid-cols-3 gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 sm:grid-cols-1">
                        {activityTypeQuestions?.[language]?.map(
                          (question: any, index: any) => (
                            <QuestionItem
                              key={question.id}
                              question={question}
                              index={index}
                              language={language}
                              responses={activityTypeQuestions}
                              showQuestion={showQuestion(
                                index,
                                language,
                                CollapseItems.PART_2_LABEL,
                              )}
                              isFillInTheBlanksEmpty={isFillInTheBlanksEmpty}
                              isMatchingEmpty={isMatchingEmpty}
                              isScenarioBasedQuestionEmpty={
                                isScenarioBasedQuestionEmpty
                              }
                            />
                          ),
                        )}
                      </div>
                    </div>
                  </div>
                ),
              )}
          </div>
        ),
      });
    }
    if (hasViva) {
      items.push({
        key: CollapseItems.PART_3_KEY,
        header: CollapseItems.PART_3_LABEL,
        disabled: selectedPart !== CollapseItems.PART_3_LABEL,
        collapsible: selectedPart !== CollapseItems.PART_3_LABEL,
        children: (
          <div>
            {vivaQuestions &&
              Object.keys(vivaQuestions)?.map((language, sectionIndex) => (
                <div key={`${language}-${sectionIndex}`} className="pb-[20px]">
                  <div className="flex flex-col justify-center items-center">
                    <div className="pb-[20px]">section {sectionIndex + 1}</div>
                    <div className="grid grid-cols-3 gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 sm:grid-cols-1">
                      {vivaQuestions?.[language]?.map(
                        (question: any, index: any) => (
                          <QuestionItem
                            key={question.id}
                            question={question}
                            index={index}
                            language={language}
                            responses={vivaQuestions}
                            showQuestion={showQuestion(
                              index,
                              language,
                              CollapseItems.PART_3_LABEL,
                            )}
                            isFillInTheBlanksEmpty={isFillInTheBlanksEmpty}
                            isMatchingEmpty={isMatchingEmpty}
                            isScenarioBasedQuestionEmpty={
                              isScenarioBasedQuestionEmpty
                            }
                          />
                        ),
                      )}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        ),
      });
    }
  }

  return (
    <div className="overflow-y-scroll border-[2px] border-[lightgray] pt-[30px] px-[20px] pb-[20px] h-[65%] mt-[10px] rounded mb-[10px]">
      <Collapse activeKey={activeKey} onChange={onChange}>
        {items.map((item) => (
          <Collapse.Panel
            key={item.key}
            header={item?.header}
            disabled={item?.disabled}>
            {item?.children}
          </Collapse.Panel>
        ))}
      </Collapse>
    </div>
  );
};

export default RightComponentCollapse;
