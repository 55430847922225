import React, { useContext, useEffect } from "react";
import { ItemStateContext } from "@/contexts/questionContext";
import { WebcamStatusCheckerProps } from "@/types/componentTypes";

const WebcamStatusChecker: React.FC<WebcamStatusCheckerProps> = ({
  onAllow,
}) => {
  const { setAllPermissions }: any = useContext(ItemStateContext);

  useEffect(() => {
    const checkWebcamStatus = async () => {
      try {
        await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        setAllPermissions((prev: any) => ({
          ...prev,
          webcamEnabled: true,
        }));
        if (onAllow) {
          onAllow();
        }
      } catch (error) {
        setAllPermissions((prev: any) => ({
          ...prev,
          webcamEnabled: false,
        }));
      }
    };

    const timerId = setTimeout(() => {
      checkWebcamStatus();
    }, 0);

    return () => {
      clearTimeout(timerId);
    };
  }, [setAllPermissions, onAllow]);

  return <div></div>;
};

export default WebcamStatusChecker;
